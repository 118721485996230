import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import BackButton from "../../BackButton";
import { restoreFile } from "../../../assets/images";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useSelector } from "react-redux";
import { lightTheme } from "../../../vars/theme";

// const activeTheme = localStorage.getItem("theme");

const useStyles = makeStyles((theme) => ({
  // root: {
  //     margin: "0 auto",
  //     padding: "0 20px 0px 14px",
  //     "& .MuiContainer-root": {
  //         maxWidth: 560,
  //     },
  //     "& .MuiDialog-paper": {
  //         border: "solid 1px rgba(255, 255, 255, 0.13)",
  //         backgroundColor: " rgba(255, 255, 255, 0.13) !important",
  //         backdropFilter: "blur(20px)",
  //     },
  // },

  dialogBoxWidth: (props) => ({
    maxWidth: "508px",
    padding: "10px 12px",
    border: "solid 1px transparent",
    backgroundColor: theme.palette.primary.light,
    backdropFilter: "blur(10px)",
    borderRadius: 0,
    "& .MuiButton-root.Mui-disabled": {
      color: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.secondary.main}`,
      opacity: 0.5,
    },
  }),
  main: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
  },
  title: {
    fontFamily: "Graphik-Extralight",
    fontSize: "24px",
    fontWeight: "normal",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.23,
    letterSpacing: "2px",
    textAlign: "center",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
  },
  drag: {
    color: theme.palette.primary.main,
    border: "1px solid #fff",
    height: 100,
    width: "100%",
    maxWidth: "90%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px auto",
    position: "relative",
  },
  leftLine: {
    position: "absolute",
    width: "22.7px",
    height: "2px",
    margin: "22px -12px",
    backgroundColor: "#25c5f7",
    left: 0,
    zIndex: 1,
  },
  maskLeft: (props) => ({
    position: "absolute",
    margin: "15px -12px",
    left: 0,
    width: "22.7px",
    height: "16px",
    background: props.activeTheme === lightTheme ? "#eef2f5" : "rgb(39 35 43)",
    zIndex: 1,
  }),
  rightLine: {
    position: "absolute",
    width: "22.7px",
    height: "2px",
    margin: "-25px -12px",
    right: "0px",
    backgroundColor: "#25c5f7",
    zIndex: 1,
  },
  maskRight: (props) => ({
    position: "absolute",
    margin: "-32px -12px",
    width: "22.7px",
    height: "16px",
    right: "0px",
    zIndex: 1,
    background: props.activeTheme === lightTheme ? "#eef2f5" : "rgb(39 35 43)",
  }),
  leftLine1: {
    position: "absolute",
    width: "22.7px",
    height: "2px",
    margin: "20px -12px",
    backgroundColor: "#25c5f7",
    left: 0,
    zIndex: 1,
  },
  maskLeft1: (props) => ({
    position: "absolute",
    margin: "13px -12px",
    left: 0,
    width: "22.7px",
    height: "16px",
    background: props.activeTheme === lightTheme ? "#eef2f5" : "rgb(39 35 43)",
    zIndex: 1,
  }),
  rightLine1: {
    position: "absolute",
    width: "22.7px",
    height: "2px",
    margin: "19px -12px",
    right: "0px",
    backgroundColor: "#25c5f7",
    zIndex: 1,
  },
  maskRight1: (props) => ({
    position: "absolute",
    margin: "12px -12px",
    width: "22.7px",
    height: "16px",
    right: "0px",
    zIndex: 1,
    background: props.activeTheme === lightTheme ? "#eef2f5" : "rgb(39 35 43)",
  }),
  leftLine2: {
    position: "absolute",
    width: "22.7px",
    height: "2px",
    margin: "20px -12px",
    backgroundColor: "#25c5f7",
    left: 0,
    zIndex: 1,
  },
  maskLeft2: (props) => ({
    position: "absolute",
    margin: "13px -12px",
    left: 0,
    width: "22.7px",
    height: "16px",
    background: props.activeTheme === lightTheme ? "#eef2f5" : "rgb(39 35 43)",
    zIndex: 1,
  }),
  rightLine2: {
    position: "absolute",
    width: "22.7px",
    height: "2px",
    margin: "19px -12px",
    right: "0px",
    backgroundColor: "#25c5f7",
    zIndex: 1,
  },
  maskRight2: (props) => ({
    position: "absolute",
    margin: "12px -12px",
    width: "22.7px",
    height: "16px",
    right: "0px",
    zIndex: 1,
    background: props.activeTheme === lightTheme ? "#eef2f5" : "rgb(39 35 43)",
  }),
  leftLine3: {
    position: "absolute",
    width: "22.7px",
    height: "2px",
    margin: "20px -12px",
    backgroundColor: "#25c5f7",
    left: 0,
    zIndex: 1,
  },
  maskLeft3: (props) => ({
    position: "absolute",
    margin: "13px -12px",
    left: 0,
    width: "22.7px",
    height: "16px",
    background: props.activeTheme === lightTheme ? "#eef2f5" : "rgb(39 35 43)",
    zIndex: 1,
  }),
  rightLine3: {
    position: "absolute",
    width: "22.7px",
    height: "2px",
    margin: "19px -12px",
    right: "0px",
    backgroundColor: "#25c5f7",
    zIndex: 1,
  },
  maskRight3: (props) => ({
    position: "absolute",
    margin: "12px -12px",
    width: "22.7px",
    height: "16px",
    right: "0px",
    zIndex: 1,
    background: props.activeTheme === lightTheme ? "#eef2f5" : "rgb(39 35 43)",
  }),
  buttonBox: {
    position: "relative",
    display: "flex",
    width: "90%",
    justifyContent: "space-between",
    margin: "20px auto",

    [theme.breakpoints.down("xs")]: {
      width: "70%",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  button: {
    fontFamily: "Graphik-Regular",
    width: "90%",
    position: "relative",
    margin: "40px auto 20px auto",
  },
  buttonNext: {
    padding: "6px 40px",
    width: 150,
    position: "relative",
    fontFamily: "Graphik-Regular",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
    },
  },
  imgRestor: {
    margin: "20px auto 40px auto",
  },
  inputFieldStyle: {
    width: "100%",
    marginBottom: "23px",
    height: "44px",
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "& .MuiOutlinedInput-root:focused ": {
      borderColor: "transparent",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent !important",
      outline: "none !important",
      fontFamily: "Graphik-Extralight",
    },
    "& .MuiOutlinedInput-input": {
      padding: "0px 16px",
      backgroundColor: "transparent !important",
      fontFamily: "Graphik-Extralight",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "transparent !important",
    },
  },
  circle: {
    color: theme.palette.white,
  },
  fileNameContainer: {
    width: "90%",
    position: "relative",
    marginTop: "20px",
  },
  passwordBox: {
    width: "100%",
    position: "relative",
    margin: "0px auto 16px auto",
  },
  eyebox: {
    width: 26,
    height: 26,
    objectFit: "contain",
    position: "absolute",
    right: 18,
    top: 8,
    cursor: "pointer",
  },
  errorSpan: {
    width: "90%",
    color: "red",
    fontWeight: 700,
    fontSize: 12,
    fontFamily: "Graphik-ExtraLight",
    marginTop: -26,
    position: "absolute",
    textAlign: "left",
  },
  label: {
    color: theme.palette.secondary.main,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const KeyStoreFile = (props) => {
  const {
    isOpen,
    toggle,
    keystoreFileHandler,
    file,
    fileName,
    onChange,
    value,
    submitPassword,
    isLoading,
    error,
  } = props;

  const activeTheme = useSelector((state) => state.theme.theme);
  const { t, li8n } = useTranslation();
  const classes = useStyles({ activeTheme });
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const fileElem = useRef(null);

  return (
    <div className={classes.root}>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Dialog
              // className={classes.root}
              open={isOpen}
              onClose={toggle}
              TransitionComponent={Transition}
              classes={{
                paperWidthSm: classes.dialogBoxWidth,
              }}
            >
              <Box className={classes.main}>
                <img
                  src={restoreFile}
                  alt="restoreImg"
                  className={classes.imgRestor}
                />
                <Typography className={classes.title}>
                  {t("keystore.UnlockyourWallet")}
                  <br />
                  {t("keystore.ViaKeyStoreFile")}
                </Typography>
                {/* <Box className={classes.drag}>
                                    Drag and drop you file here
                                    <span className={classes.maskLeft} />
                                    <span className={classes.leftLine} />
                                    <span className={classes.maskRight} />
                                    <span className={classes.rightLine} />
                                </Box> */}

                {fileName ? (
                  <>
                    <div className={classes.fileNameContainer}>
                      <span className={classes.maskLeft3} />
                      <span className={classes.leftLine3} />
                      <TextField
                        className={classes.inputFieldStyle}
                        variant="outlined"
                        placeholder={fileName}
                        fullWidth
                        disabled
                      />

                      <span className={classes.maskRight3} />
                      <span className={classes.rightLine3} />
                    </div>
                  </>
                ) : (
                  <>
                    <Button
                      variant="outlined"
                      color="secondary"
                      className={classes.button}
                      onClick={() => {
                        fileElem.current && fileElem.current.click();
                      }}
                    >
                      {t("keystore.UploadFile")}
                      <input
                        name="file"
                        type="file"
                        ref={fileElem}
                        // multiple
                        accept=".json"
                        style={{ display: "none" }}
                        onChange={(e) => keystoreFileHandler(e)}
                      />
                      <span className={classes.maskLeft2} />
                      <span className={classes.leftLine2} />
                      <span className={classes.maskRight2} />
                      <span className={classes.rightLine2} />
                    </Button>
                  </>
                )}
                {file ? (
                  <Box style={{ width: "90%", position: "relative" }}>
                    <Typography
                      variant="h4"
                      style={{ margin: "16px 0px" }}
                      className={classes.label}
                    >
                      {t("keystore.Pleasewriteyourpassword")}
                    </Typography>
                    <form
                      onSubmit={submitPassword}
                      className={classes.passwordBox}
                    >
                      <span className={classes.maskLeft1} />
                      <span className={classes.leftLine1} />
                      <TextField
                        className={classes.inputFieldStyle}
                        type={showPassword ? "text" : "password"}
                        onChange={onChange}
                        value={value}
                        variant="outlined"
                        placeholder={t("keystore.Password")}
                        fullWidth
                        name="password"
                      />
                      <span className={classes.maskRight1} />
                      <span className={classes.rightLine1} />
                      <Box onClick={handleClickShowPassword}>
                        {showPassword ? (
                          <VisibilityIcon className={classes.eyebox} />
                        ) : (
                          <VisibilityOffIcon className={classes.eyebox} />
                        )}
                      </Box>
                    </form>
                    {error ? (
                      <span className={classes.errorSpan}>
                        {t("keystore.PasswordandFileisnotvalid")}
                      </span>
                    ) : null}
                  </Box>
                ) : null}
                <Box className={classes.buttonBox}>
                  <BackButton
                    title={t("keystore.Back")}
                    maxWidth={"150px"}
                    onClick={toggle}
                  />

                  <Button
                    variant="outlined"
                    color="secondary"
                    className={classes.buttonNext}
                    onClick={submitPassword}
                    disabled={!file}
                  >
                    {isLoading ? (
                      <CircularProgress
                        variant="indeterminate"
                        disableShrink
                        className={classes.top}
                        classes={{
                          circle: classes.circle,
                        }}
                        size={30}
                        thickness={4}
                      />
                    ) : (
                      <> {t("keystore.UnlockWallet")}</>
                    )}
                    <span className={classes.maskLeft} />
                    <span className={classes.leftLine} />
                    <span className={classes.maskRight} />
                    <span className={classes.rightLine} />
                  </Button>
                </Box>
              </Box>
            </Dialog>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default KeyStoreFile;
