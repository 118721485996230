export const SESSION_LOGIN = "SESSION_LOGIN";
export const SESSION_LOGOUT = "SESSION_LOGOUT";
export const MNEMONIC_WORDS = "MNEMONIC_WORDS";
export const SESSION_OPEN = "SESSION_OPEN";
export const SESSION_CLOSE = "SESSION_CLOSE";

export const login = () => (dispatch) =>
  dispatch({
    type: SESSION_LOGIN,
  });

export const logout = () => (dispatch) =>
  dispatch({
    type: SESSION_LOGOUT,
  });
export const modalopen = () => (dispatch) =>
  dispatch({
    type: SESSION_OPEN,
  });
export const modalclose = () => (dispatch) =>
  dispatch({
    type: SESSION_CLOSE,
  });
export const getMnemonics = (data) => (dispatch) =>
  dispatch({
    type: MNEMONIC_WORDS,
    payload: data,
  });
