export const showNotification = (notificationType, status, options, txHash) => {
  try {
    if (status == "granted") {
      const notification = new Notification(notificationType, options);
      txHash &&
        notification.addEventListener("click", () => {
          window.open(
            `https://main.explorer.creatachain.com/txs/${txHash}`,
            "_blank"
          );
        });
    }
  } catch (error) {}
};
