/* eslint-disable no-unused-vars */
import React from "react";
// import { Link as RouterLink } from 'react-router-dom';
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Container, Box, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import {
    appstore,
    appstoreLight,
    playstore,
    playstoreLight,
    twitter,
    telegram,
    bindo,
    linkedin,
    reddit,
    facebook,
    youtube,
    medium,
    medium1,
    discord,
} from "../../assets/images";

import { lightTheme } from "../../vars/theme";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    footerContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: theme.palette.white,
        margin: " 30px auto",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            flexWrap: "wrap",
            display: "inherit",
        },
        [theme.breakpoints.down("xs")]: {
            flexWrap: "wrap",
            display: "inherit",
        },
    },
    leftSection: {
        // flex: 1,
        width: "37%",
        [theme.breakpoints.down("sm")]: {
            marginBottom: 16,
        },
        [theme.breakpoints.down("xs")]: {
            marginBottom: 6,
        },
    },
    content: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            flexWrap: "wrap",
        },
    },
    rightSection: {
        display: "flex",
        justifyContent: "space-between",
        flex: 1,
        width: "100%",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            // flexWrap: "wrap",
            width: "100%",
            justifyContent: "flex-start",
        },
        [theme.breakpoints.down("xs")]: {
            flexWrap: "wrap",
            width: "100%",
        },
    },
    storeLink: {
        height: "40px",
        marginRight: "18px",
        cursor: "pointer",
    },
    linkStyle: {
        textDecoration: "none",
        marginRight: "100px",
    },
    iconContainer: {
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
        [theme.breakpoints.down("xs")]: {
            flexWrap: "wrap",
        },
    },
    icons: {
        display: "flex",
        flexDirection: "column",
        // width: "70%",
        [theme.breakpoints.down("xs")]: {
            flexWrap: "wrap",
            width: "100%",
        },
    },
    iconContent: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        width: "100%",
        // marginBottom: "6px",
        [theme.breakpoints.down("sm")]: {
            justifyContent: "flex-start",
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: 8,
            flexWrap: "wrap",
        },
    },
    textStyle: {
        lineHeight: 2,
        color: theme.palette.secondary.main,
        whiteSpace: "nowrap",
    },
    privacyStyle: {
        display: "flex",
    },
    textStyles: {
        lineHeight: 2,
        color: theme.palette.secondary.main,
        whiteSpace: "nowrap",
        [theme.breakpoints.down("xs")]: {
            marginTop: -6,
        },
    },
    link: {
        cursor: "pointer",
        objectFit: "contain",
        height: 15,
        width: 22,
        margin: "0px 10px 0px 0px",
        [theme.breakpoints.down("xs")]: {
            margin: "0px 20px 0px 0px",
        },
    },
}));

const TopBar = (props) => {
    const { className } = props;
    const { t } = useTranslation();

    const activeTheme = useSelector((state) => state.theme.theme);
    const classes = useStyles({ activeTheme });

    return (
        <Container>
            <Box className={classes.footerContainer}>
                <Box className={classes.leftSection}>
                    <Box className={classes.content}>
                        <div className={classes.linkStyle}>
                        <a
                        href="https://creatachain.com/ourstory#faq"
                        target="_blank"
                        style={{textDecoration:'none'}}
                        >
                            <Typography variant="body1" className={classes.textStyle}>
                                {t("footer.NeedHelp")}
                            </Typography></a>
                        </div>
                    </Box>
                </Box>
                <Box className={classes.rightSection}>
                    <a
                        href="https://play.google.com/store/apps/details?id=com.creatawallet"
                        target="_blank">
                        <Box>
                            <img
                                alt="facebook"
                                src={activeTheme === lightTheme ? playstoreLight : playstore}
                                className={classes.storeLink}
                            />
                        </Box>
                    </a>
                    <a href="https://apps.apple.com/app/id6443877662" target="_blank">
                        <Box>
                            <img
                                alt="facebook"
                                src={activeTheme === lightTheme ? appstoreLight : appstore}
                                className={classes.storeLink}
                            />
                        </Box>
                    </a>
                    <Box className={classes.iconContainer}>
                        <Box className={classes.icons}>
                            <Box className={classes.iconContent}>
                                <a
                                    target="_blank"
                                    href="https://www.facebook.com/profile.php?id=100055222337424"
                                    rel="noreferrer">
                                    <img src={facebook} alt="logo" className={classes.link} />
                                </a>

                                <a
                                    target="_blank"
                                    href="https://twitter.com/creatachain"
                                    rel="noreferrer">
                                    <img src={twitter} alt="logo" className={classes.link} />
                                </a>
                                <a
                                    target="_blank"
                                    href="https://www.youtube.com/channel/UCKTWx-xms6q-bEp68GmFe1w"
                                    rel="noreferrer">
                                    <img src={youtube} alt="logo" className={classes.link} />
                                </a>
                                <a
                                    target="_blank"
                                    href="https://discord.com/channels/890824552085614632/890824552836378666"
                                    rel="noreferrer">
                                    <img src={discord} alt="logo" className={classes.link} />
                                </a>
                                <a
                                    target="_blank"
                                    href="https://www.linkedin.com/in/creata-chain-18b071237/"
                                    rel="noreferrer">
                                    <img src={linkedin} alt="logo" className={classes.link} />
                                </a>

                                <a
                                    target="_blank"
                                    href="https://medium.com/@creatachain"
                                    rel="noreferrer">
                                    {/* <img src={medium} alt="logo" className={classes.link} /> */}
                                    <img
                                        src={medium1}
                                        alt="logo"
                                        className={classes.link}
                                        style={{ marginTop: 5 }}
                                    />
                                </a>

                                <a
                                    target="_blank"
                                    href="https://www.reddit.com/user/creatachian"
                                    rel="noreferrer">
                                    <img src={reddit} alt="logo" className={classes.link} />
                                </a>
                            </Box>
                            <Box className={classes.privacyStyle}>
                                <Link
                                    to="/terms-and-conditions"
                                    style={{ textDecoration: "none", marginRight: "30px" }}>
                                    <Typography variant="body1" className={classes.textStyle}>
                                        {t("footer.Terms")}
                                    </Typography>
                                </Link>
                                <Link to="/privacy-policy" style={{ textDecoration: "none" }}>
                                    <Typography variant="body1" className={classes.textStyle}>
                                        {t("footer.PrivacyPolicy")}
                                    </Typography>
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

TopBar.propTypes = {
    className: PropTypes.string,
    onOpenNavBarMobile: PropTypes.func,
};

export default TopBar;
