/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @next/next/no-img-element */
import { Container, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TableInfo from "./Table";
import Bullet from "../assets/images/privacypolicy/bullet.png";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: "auto",
  },
  imageContainer: {
    marginRight:"16px",
  },
  h1: {
    color:theme.palette.primary.darklight,
    fontSize: "22px",
    lineHeight: 2,
  [theme.breakpoints.down("xs")]: {
    lineHeight: 1.5,
    fontSize: "18px",
    },

    fontWeight: "normal",
    textAlign: "left",
    fontFamily: "Graphik-Regular",
  },

  detail: {
    color:theme.palette.primary.darklight,
    fontFamily: "Graphik-Light",
    fontSize: "16px",
    lineHeight: 2,
    textAlign: "left",
    fontWeight: 300,
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  opacity: {
    opacity: "0.5",
  },
  iconContainer:
  {
    display: "flex",
    width: "100%",
    alignItems:"center",
  },
  mainContainer:
  {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    "& > :not(style)": {
      marginTop: 10,
      marginBottom: 10,
      [theme.breakpoints.down("xs")]: {
        marginTop: 5,
        marginBottom: 5,
      },
    },
  }

}));

const PrivacyPolicy = () => {

  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <div className={classes.root}>
      <Container>
        <Box
        className={classes.mainContainer}
        >
          <Box className={classes.iconContainer}>
            <Box className={classes.imageContainer}>
            <img src={Bullet} alt="bullet" />
            </Box>
            <Typography
              variant="body1"
              className={classes.h1}
              id="Privacypolicy"
            >
              {t("privacy.main")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" className={classes.detail}>
            {t("privacy.part1")}&nbsp;
              <a
                style={{ color: "#007fc1" }}
                href="https://creatachain.com"
                target="_blank"
                rel="noreferrer"
              >
                www.creatachain.com
              </a>{" "}
              {t("privacy.part2")}
            </Typography>
          </Box>
          <Box className={classes.iconContainer}>
            <Box className={classes.imageContainer}>
              <img  src={Bullet} alt="bullet" />
            </Box>
            <Typography variant="body1" className={classes.h1} id="InfoCollect">
            {t("privacy.head1")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" className={classes.detail}>
              {t("privacy.para1")} 
            </Typography>
          </Box>
          <Box className={classes.iconContainer}>
            <Box className={classes.imageContainer}>
            <img  src={Bullet}  alt="bullet" />
            </Box>
            <Typography variant="body1" className={classes.h1} id="Infouse">
              {t("privacy.head2")} 
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" className={classes.detail}>
              {t("privacy.para2")}  
            </Typography>
          </Box>
          <Box className={classes.iconContainer}>
            <Box className={classes.imageContainer}>
            <img  src={Bullet} alt="bullet" />
            </Box>
            <Typography variant="body1" className={classes.h1} id="Infoprotect">
            {t("privacy.head3")}  
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" className={classes.detail}>
            {t("privacy.para3")} 
            </Typography>
          </Box>
          <Box style={{display: "flex",width:"100%",
               justifyContent: "center"}}>
            <TableInfo />
          </Box>
          <Box className={classes.iconContainer}>
            <Box className={classes.imageContainer}>
              <img  src={Bullet}  alt="bullet" />
            </Box>
            <Typography variant="body1" className={classes.h1} id="Infoshare">
            {t("privacy.head4")}
             
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" className={classes.detail}>
            {t("privacy.para4")}
            </Typography>
          </Box>
          <Box className={classes.iconContainer}>
            <Box className={classes.imageContainer}>
            <img  src={Bullet} alt="bullet" />
            </Box>
            <Typography
              variant="body1"
              className={classes.h1}
              id="Privacypolicy"
            >
              {t("privacy.head5")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" className={classes.detail}>
            {t("privacy.para5")}
            </Typography>
            {/* ..............................here................................... */}
          </Box>
          <Box className={classes.iconContainer}>
            <Box className={classes.imageContainer}>
            <img  src={Bullet}  alt="bullet" />
            </Box>
            <Typography
              variant="body1"
              className={classes.h1}
              id="Privacypolicy"
            >
             {t("privacy.head6")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" className={classes.detail}>
            {t("privacy.para6")}
            </Typography>
          </Box>
          <Box className={classes.iconContainer}>
            <Box className={classes.imageContainer}>
            <img  src={Bullet}  alt="bullet" />
            </Box>
            <Typography
              variant="body1"
              className={classes.h1}
              id="Privacypolicy"
            >
               {t("privacy.head7")}
             
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" className={classes.detail}>
            {t("privacy.para7")}
            </Typography>
          </Box>
          <Box className={classes.iconContainer}>
            <Box className={classes.imageContainer}>
            <img  src={Bullet} alt="bullet" />
            </Box>
            <Typography
              variant="body1"
              className={classes.h1}
              id="Privacypolicy"
            >
              {t("privacy.head8")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" className={classes.detail}>
            {t("privacy.para8")}
            </Typography>
          </Box>
          <Box className={classes.iconContainer}>
            <Box className={classes.imageContainer}>
            <img  src={Bullet} alt="bullet" />
            </Box>
            <Typography
              variant="body1"
              className={classes.h1}
              id="Privacypolicy"
            >
            {t("privacy.head9")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" className={classes.detail}>
            {t("privacy.para9")}
            </Typography>
          </Box>
          <Box className={classes.iconContainer}>
            <Box className={classes.imageContainer}>
            <img  src={Bullet}  alt="bullet" />
            </Box>
            <Typography
              variant="body1"
              className={classes.h1}
              id="Privacypolicy"
            >
              {t("privacy.head10")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" className={classes.detail}>
            {t("privacy.para10")}
            </Typography>
          </Box>
          <Box className={classes.iconContainer}>
            <Box className={classes.imageContainer}>
            <img  src={Bullet}  alt="bullet" />
            </Box>
            <Typography
              variant="body1"
              className={classes.h1}
              id="Privacypolicy"
            >
              {t("privacy.head11")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" className={classes.detail}>
            {t("privacy.head12")}
              <ul>
                <li>
                  <b>{t("privacy.item1")}</b> info@creatachain.io
                </li>
                <li>
                  <b>{t("privacy.item2")}</b> CREATACHAIN PTE. LTD. GSM BUILDING,
                  141 MIDDLE ROAD, #05-06, Postal 188976
                </li>
              </ul>{" "}
              {t("privacy.item3")}!
            </Typography>
          </Box>
        </Box>
      </Container>
    </div>
    
  );
};

export default PrivacyPolicy;
