import arr from "../fontsConfig";

export default {
  "@global": {
    //override the pseudo-classes
    ".Mui-disabled": { opacity: 1, border: "1px solid #fff" },
    ".Mui-selected": { background: "red" },
  },
  "@App": {
    "@font-face": arr,
},
};
