import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Box, Typography, Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { useSelector } from "react-redux";
import { copyIcon, copyLightIcon } from "../../../assets/images";
import copy from "copy-to-clipboard";
import { lightTheme } from "../../../vars/theme";
import { useTranslation } from "react-i18next";
// const activeTheme = localStorage.getItem("theme");

const useStyles = makeStyles((theme) => ({
    dialogBoxWidth: {
        maxWidth: "508px",
        padding: "22px 26px",
        border: "solid 1px transparent",
        backgroundColor: theme.palette.primary.light,
        backdropFilter: "blur(10px)",
        borderRadius: 0,
    },
    title: { color: theme.palette.secondary.main },
    phraseContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        maxWidth: "600px",
        margin: "auto",
        border: `solid 0.8px ${theme.palette.secondary.main}`,
        position: "relative",
        padding: "10px",
    },
    phaseBoxContainer: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        flexWrap: "wrap",
        minHeight: "120px",
        [theme.breakpoints.down("xs")]: {
            height: "auto",
        },
    },
    boxContainer: (props) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: props.activeTheme == lightTheme ? "rgba(9, 9, 9, 0.1)" : "#ffffff26",
        width: "110px",
        height: "30px",
        marginBottom: 9,
        [theme.breakpoints.down("xs")]: {
            marginBottom: 5,
        },
    }),
    phaseBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    phaseBoxContent: {
        textAlign: "left",
        marginRight: "8px",
        color: theme.palette.secondary.main,
    },
    leftLine: {
        position: "absolute",
        width: "22.7px",
        height: "2px",
        margin: "18px -22px",
        backgroundColor: "#25c5f7",
        zIndex: 1,
    },
    maskLeft: (props) => ({
        position: "absolute",
        margin: "12px -22px",
        width: "22.7px",
        height: "16px",
        background: props.activeTheme === lightTheme ? "#eef2f5" : "rgb(43 37 45)",
        zIndex: 1,
    }),
    rightLine: {
        position: "absolute",
        width: "22.7px",
        height: "2px",
        margin: "18px -12px",
        right: "0px",
        backgroundColor: "#25c5f7",
        zIndex: 1,
    },
    maskRight: (props) => ({
        position: "absolute",
        margin: "12px -12px",
        width: "22.7px",
        height: "16px",
        right: "0px",
        zIndex: 1,
        background: props.activeTheme === lightTheme ? "#eef2f5" : "rgb(43 37 45)",
    }),
    leftLine1: {
        position: "absolute",
        width: "22.7px",
        height: "2px",
        margin: "18px -12px",
        backgroundColor: "#25c5f7",
        left: 0,
        zIndex: 1,
    },
    maskLeft1: (props) => ({
        position: "absolute",
        margin: "12px -12px",
        width: "22.7px",
        height: "16px",
        background: props.activeTheme === lightTheme ? "#eef2f5" : "#27232a",
        left: 0,
        zIndex: 1,
    }),
    rightLine1: {
        position: "absolute",
        width: "22.7px",
        height: "2px",
        margin: "18px -12px",
        right: "0px",
        backgroundColor: "#25c5f7",
        zIndex: 1,
    },
    maskRight1: (props) => ({
        position: "absolute",
        margin: "12px -12px",
        width: "22.7px",
        height: "16px",
        right: "0px",
        zIndex: 1,
        background: props.activeTheme === lightTheme ? "#eef2f5" : "#27232a",
    }),
    btn: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        marginBottom: 16,
    },
    button: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        maxWidth: 165,
        height: 40,
    },
    copyText: {
        display: "flex",
        marginTop: -12,
        alignItems: "center",
        color: theme.palette.secondary.main,
        cursor: "pointer",
    },
    copyImg: {
        marginRight: 6,
        objectFit: "contain",
        cursor: "pointer",
        height: 20,
    },
    copied: {
        fontWeight: "bold",
        color: theme.palette.secondary.main,
    },
    text: {
        color: theme.palette.secondary.main,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const BackupMnemonics = (props) => {
    const { t, li8n } = useTranslation();
    const { isOpen, toggle, mnemonic } = props;
    const activeTheme = useSelector((state) => state.theme.theme);
    const classes = useStyles({ activeTheme });
    const [wasCopied, setWasCopied] = useState(false);
    // const { mnemonic } = useSelector((state) => state.wallet);

    const nemonics = mnemonic && mnemonic.split(" ");
    const copyMnemonics = () => {
        const arr = [];
        nemonics &&
            nemonics.map((item, i) =>
                arr.push({
                    mnemonicsName: item,
                    index: i,
                }),
            );
        const newMnemonic = arr.map(
            (mnmonics) => mnmonics.index + 1 + ": " + mnmonics.mnemonicsName,
        );

        copy(newMnemonic.join(" "));
        setWasCopied(true);
        setTimeout(() => {
            setWasCopied(false);
        }, 3000);
    };

    return (
        <div className={classes.root}>
            <Container>
                <Grid container>
                    <Grid item xs={12}>
                        <Dialog
                            className={classes.root}
                            open={isOpen}
                            // onClose={toggle}
                            TransitionComponent={Transition}
                            classes={{
                                paperWidthSm: classes.dialogBoxWidth,
                            }}>
                            <Box>
                                <Typography className={classes.title} variant="body1">
                                    {t("backupmnemonic.BackUpPhrase")}{" "}
                                </Typography>
                                <Box
                                    className={classes.phraseContainer}
                                    style={{ marginBottom: "18px" }}
                                // ref={(el) => (this.componentRef = el)}
                                >
                                    <span className={classes.maskLeft} />
                                    <span className={classes.leftLine} />
                                    <Box className={classes.phaseBoxContainer}>
                                        {nemonics &&
                                            nemonics.map((item, index) => (
                                                <Box className={classes.phaseBox} key={index}>
                                                    <div className={classes.boxContainer}>
                                                        <Typography
                                                            className={classes.phaseBoxContent}
                                                            variant="subtitle2">
                                                            {index + 1}.
                                                        </Typography>

                                                        <Typography
                                                            className={classes.phaseBoxContent}
                                                            variant="subtitle2">
                                                            {item}
                                                        </Typography>
                                                    </div>
                                                </Box>
                                            ))}
                                    </Box>

                                    <span className={classes.maskRight} />
                                    <span className={classes.rightLine} />
                                </Box>

                                <Box className={classes.copyText} onClick={copyMnemonics} >
                                    <img
                                        src={activeTheme == lightTheme ? copyLightIcon : copyIcon}
                                        alt="img"
                                        className={classes.copyImg}
                                    />
                                    {wasCopied ? (
                                        <Typography variant="body1" className={classes.copied}>
                                            {t("backupmnemonic.Copied")}
                                        </Typography>
                                    ) : (
                                        <Typography className={classes.text} variant="body1">
                                            {t("backupmnemonic.Copy")}{" "}
                                        </Typography>
                                    )}
                                </Box>
                                <Box className={classes.btn}>
                                    <Button
                                        className={classes.button}
                                        onClick={toggle}
                                        variant="outlined"
                                        color="secondary">
                                        {t("backupmnemonic.Close")}
                                        <span className={classes.maskLeft1} />
                                        <span className={classes.leftLine1} />
                                        <span className={classes.maskRight1} />
                                        <span className={classes.rightLine1} />
                                    </Button>
                                </Box>
                            </Box>
                        </Dialog>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};
export default BackupMnemonics;
