import { createTheme } from "@material-ui/core";

import palette from "./palette";
import typography from "./typography";
import overrides from "./overrides";
import breakpoints from "./breakpoints";
import arr from "./fontsConfig";

const darkTheme = createTheme({
  palette,
  typography: {
    fontFamily: ["Graphik", "Metropolis"].join(","),
    ...typography,
  },
  overrides: {
    ...overrides,
  },
  breakpoints,
});

export default darkTheme;
