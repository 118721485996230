import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Container,
    Grid,
    Box,
    Typography,
    Button,
    TextField,
    CircularProgress,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useSelector } from "react-redux";
import validate from "validate.js";
import { lightTheme } from "../../../vars/theme";
import { useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    dialogBoxWidth: {
        maxWidth: "508px",
        padding: "10px 12px",
        border: "solid 1px transparent",
        backgroundColor: theme.palette.primary.light,
        backdropFilter: "blur(10px)",
        borderRadius: 0,
        padding: " 6px 26px ",
    },
    contentContainer: {
        marginBottom: 30,
    },
    title: {

        fontSize: 18, lineHeight: 2,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "0.4px",
        color: theme.palette.secondary.main,
        textTransform: "uppercase",
    },
    titleTextColor: {
        color: "#007fc1",
    },
    subtitle: {
        textAlign: "center",
        marginBottom: "18px",
        color: theme.palette.secondary.main,
    },
    content: {
        textAlign: "left",
        marginBottom: "20px",
        color: theme.palette.secondary.main,
    },
    phraseContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        maxWidth: "600px",
        margin: "auto",
        border: `solid 0.8px ${theme.palette.secondary.main}`,
        position: "relative",
        padding: "10px",
    },
    phaseBoxContainer: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        flexWrap: "wrap",
        height: "120px",
    },
    boxContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#ffffff26",
        width: "110px",
        height: "30px",
    },
    phaseBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    phaseBoxContent: {
        textAlign: "left",
        marginRight: "8px",
    },
    chooseButtonContainer: {
        display: "flex",
        height: "26px",
    },
    iconButtonStyle: {
        fontSize: "10px",
        letterSpacing: "normal",
        fontWeight: "normal",
        lineHeight: 1.5,
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        width: "78px",
    },
    marginBottom: {
        marginBottom: "18px",
    },
    copiedText: {
        fontSize: "10px",
        letterSpacing: "normal",
        fontWeight: 500,
        lineHeight: 2.8,
        color: "#05b169",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-between",
        maxWidth: "370px",
        margin: "auto",
    },
    leftLine: {
        position: "absolute",
        width: "22.7px",
        height: "2px",
        margin: "18px -32px",
        backgroundColor: "#25c5f7",
        zIndex: 1,
    },
    maskLeft: (props) => ({
        position: "absolute",
        margin: "12px -32px",
        width: "22.7px",
        height: "16px",
        background: props.activeTheme === lightTheme ? "#eef2f5" : "#2b2430",
        zIndex: 1,
    }),
    rightLine: {
        position: "absolute",
        width: "22.7px",
        height: "2px",
        margin: "18px -12px",
        right: "0px",
        backgroundColor: "#25c5f7",
        zIndex: 1,
    },
    maskRight: (props) => ({
        position: "absolute",
        margin: "12px -12px",
        width: "22.7px",
        height: "16px",
        right: "0px",
        zIndex: 1,
        background: props.activeTheme === lightTheme ? "#eef2f5" : "#39343f",
    }),
    leftLine1: {
        position: "absolute",
        width: "22.7px",
        height: "2px",
        margin: "18px -12px",
        backgroundColor: "#25c5f7",
        left: 0,
        zIndex: 1,
    },
    maskLeft1: (props) => ({
        position: "absolute",
        margin: "12px -12px",
        width: "22.7px",
        height: "16px",
        background: props.activeTheme === lightTheme ? "#eef2f5" : "#2b2430",
        left: 0,
        zIndex: 1,
    }),
    rightLine1: {
        position: "absolute",
        width: "22.7px",
        height: "2px",
        margin: "18px -12px",
        right: "0px",
        backgroundColor: "#25c5f7",
        zIndex: 1,
    },
    maskRight1: (props) => ({
        position: "absolute",
        margin: "12px -12px",
        width: "22.7px",
        height: "16px",
        right: "0px",
        zIndex: 1,
        background: props.activeTheme === lightTheme ? "#eef2f5" : "#39363d",
    }),
    maskRight2: (props) => ({
        position: "absolute",
        margin: "12px -12px",
        width: "22.7px",
        height: "16px",
        right: "0px",
        zIndex: 1,
        background: props.activeTheme === lightTheme ? "#eef2f5" : "#443e43",
    }),
    leftLine2: {
        position: "absolute",
        width: "22.7px",
        height: "2px",
        margin: "25px -12px",
        backgroundColor: "#25c5f7",
        left: 0,
        zIndex: 1,
    },
    maskLeft2: (props) => ({
        position: "absolute",
        margin: "18.5px -12px",
        width: "22.7px",
        height: "16px",
        background: props.activeTheme === lightTheme ? "#eef2f5" : "#2f2833",
        left: 0,
        zIndex: 1,
    }),
    rightLine2: {
        position: "absolute",
        width: "22.7px",
        height: "2px",
        margin: "25px -12px",
        right: "0px",
        backgroundColor: "#25c5f7",
        zIndex: 1,
    },

    btn: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        marginTop: 16,
        marginBottom: 16,
    },
    btnCancel: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        maxWidth: 165,
        height: 40,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main
    },
    button: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        maxWidth: 165,
        height: 40,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main
    },
    inputBox: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        border: `solid 1px ${theme.palette.secondary.main}`,
        padding: "20px 20px",
        position: "relative",
    },
    input: {
        width: "100%",
        padding: "0px 16px ",
        marginBottom: 16,
        display: "flex",
    },
    leftText: {
        lineHeight: 1.3,
        width: "100%",
        maxWidth: 100,

        color: theme.palette.secondary.main,
    },
    rightText: {
        lineHeight: 1.3,

        width: "100%",
        maxWidth: "70%",
        textOverflow: "ellipsis",
        overflow: "hidden",
        color: theme.palette.secondary.main,
    },
    passwordBox: {
        width: "100%",
        position: "relative",
        margin: "0px auto 16px auto",
        // " & .MuiOutlinedInput-root": {
        //     border: "none",
        // },
        "& input.MuiInputBase-input.MuiOutlinedInput-input": {
            backgroundColor: "transparent !important",
        },
        "& .MuiOutlinedInput-root": {
            backgroundColor: "transparent !important",
        },
    },
    eyebox: {
        width: 26,
        height: 26,
        objectFit: "contain",
        position: "absolute",
        right: 14,
        top: 13,
        cursor: "pointer",
    },
    heading: {
        fontSize: 14,
        marginBottom: 6,
    },
    circle: {
        color: theme.palette.secondary.main,
    },
}));
const schema = (t) => {
    return {
    password: {
        presence: true,
        format: {
            pattern: /(?=^.{8,}$)(?=.*\d)(?=.*[!$%^&()_+|~=`{}\[\]:";'<>?,.#@*-\/\\]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
            message:
           <>{t('validationmessage.password')}</> 
        },
    },
};
};
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const SendModal = (props) => {
    const { isOpen, handleClose, formData, sendTxsHanlder, coin, isSending } = props;
    const activeTheme = useSelector((state) => state.theme.theme);
    const {t,li8n} =  useTranslation();
    const classes = useStyles({ activeTheme });
    const { address } = useSelector((state) => state.wallet);
    const [state, setState] = React.useState({
        password: "",
        touched: {
            password: false,
        },
    });
    const [showPassword, setShowPassword] = useState(false);
    const handleChange = (e) => {
        e.persist();
        // setPasswordError(false);
        // setPassword(e.target.value);
        setState({
            ...state,
            touched: { ...state.touched, [e.target.name]: true },
            [e.target.name]: e.target.value,
        });
    };
    let errors = validate(
        {
            password: state.password,
        },
        schema(t),
    );
    errors = errors || {};
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleSubmit = (e) => {
        setState({
            ...state,
            touched: {
                password: true,
            },
        });
        e.preventDefault();
    };
    return (
        <div className={classes.root}>
            <Container>
                <Grid container>
                    <Grid item xs={12}>
                        <Dialog
                            className={classes.root}
                            open={isOpen}
                            TransitionComponent={Transition}
                            classes={{
                                paperWidthSm: classes.dialogBoxWidth,
                            }}>
                            <Box>
                                <Typography variant="subtitle1" className={classes.title}>{t("sendmodel.Send")} {coin} </Typography>
                                <Box className={classes.inputBox}>
                                    <span className={classes.maskLeft} />
                                    <span className={classes.leftLine} />
                                    <span className={classes.maskRight} />
                                    <span className={classes.rightLine} />
                                    <Box className={classes.input}>
                                        <Typography variant="subtitle1" className={classes.leftText}>{t("sendmodel.To")}</Typography>
                                        <Typography variant="subtitle1" className={classes.rightText}>
                                            {formData.values.address}
                                        </Typography>
                                    </Box>
                                    <Box className={classes.input}>
                                        <Typography variant="subtitle1" className={classes.leftText}>{t("sendmodel.From")}</Typography>
                                        <Typography variant="subtitle1" className={classes.rightText}>
                                            {address}
                                        </Typography>
                                    </Box>
                                    <Box className={classes.input}>
                                        <Typography variant="subtitle1" className={classes.leftText}>
                                      {t("sendmodel.Amount")}
                                        </Typography>
                                        <Typography variant="subtitle1" className={classes.rightText}>
                                            {formData.values.amount}&nbsp; CTA
                                        </Typography>
                                    </Box>
                                    <Box className={classes.input}>
                                        <Typography variant="subtitle1" className={classes.leftText}>{t("sendmodel.Memo")}</Typography>
                                        <Typography variant="subtitle1" className={classes.rightText}>
                                            {formData.values.memo}
                                        </Typography>
                                    </Box>

                                    <Box className={classes.input}>
                                        <Typography variant="subtitle1" className={classes.leftText}>{t("sendmodel.Fee")}</Typography>
                                        <Typography variant="subtitle1" className={classes.rightText}>
                                            <span>0.00</span>&nbsp; CTA
                                        </Typography>
                                    </Box>
                                </Box>
                                {/* <Box style={{ marginTop: 16 }}>
                  <Typography variant="subtitle1" variant="h4" className={classes.heading}>
                    Please write your password
                  </Typography>
                  <form onSubmit={handleSubmit}>
                    <Box className={classes.passwordBox}>
                      <span className={classes.maskLeft2} />
                      <span className={classes.leftLine2} />
                      <span className={classes.maskRight2} />
                      <span className={classes.rightLine2} />
                      <TextField
                        type={showPassword ? "text" : "password"}
                        className={
                          state.touched.password && errors.password
                            ? classes.bRed
                            : classes.bGray
                        }
                        variant="outlined"
                        name="password"
                        error={state.touched.password && errors.password}
                        // helperText={state.password ? "Invalid Password" : null}
                        value={state.password || ""}
                        placeholder="Type your password"
                        fullWidth
                        onChange={handleChange}
                      />
                      {state.touched.password && errors.password ? (
                        <Typography variant="subtitle1"
                          style={{
                            color: "red",
                            fontSize: 12,
                            padding: "6px 6px 0px 6px",
                            lineHeight: 1.4,
                          }}
                        >
                          {errors.password && errors.password[0]}
                        </Typography>
                      ) : null}
                      <Box onClick={handleClickShowPassword}>
                        {showPassword ? (
                          <VisibilityIcon className={classes.eyebox} />
                        ) : (
                          <VisibilityOffIcon className={classes.eyebox} />
                        )}
                      </Box>
                    </Box>
                  </form>
                </Box> */}
                                <Box className={classes.btn}>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        className={classes.btnCancel}
                                        onClick={() => handleClose(false)}>
                                      {t("sendmodel.cancel")}
                                        <span className={classes.maskLeft1} />
                                        <span className={classes.leftLine1} />
                                        <span className={classes.maskRight1} />
                                        <span className={classes.rightLine1} />
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        className={classes.button}
                                        onClick={sendTxsHanlder}
                                        disabled={isSending}>
                                        {isSending ? (
                                            <CircularProgress
                                                variant="indeterminate"
                                                disableShrink
                                                className={classes.top}
                                                classes={{
                                                    circle: classes.circle,
                                                }}
                                                size={18}
                                                thickness={4}
                                            />
                                        ) : (
                                            <>{t("sendmodel.Send")}</>
                                        )}
                                        <span className={classes.maskLeft1} />
                                        <span className={classes.leftLine1} />
                                        <span className={classes.maskRight2} />
                                        <span className={classes.rightLine1} />
                                    </Button>
                                </Box>
                            </Box>
                        </Dialog>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};
export default SendModal;
