import * as actionTypes from "../actions";

const initialState = {
    meta: {},
    mnemonic: "",
    address: "",
    ecpairPriv: "",
    accounts: [],
    transactions: [],
    contacts: [],
    coins: [],
    coinsLoading: false,
    allTxs: null,
    allTxsLoading: false,
    selectedRow: -1,
    selectedCoin: {},
    txsLoading: false,
    graphDetail: null,
    graphDetailLoading: false,
    marketPrice: {
        usd: "",
    },
    psixMarketPrice: {
        price: "",
    },
    isSending: false,
    isSuccessOpen: false,
    isErrorOpen: false,
    successText: "",
    errorText: "",
    isFileSuccessOpen: false,
    fileSuccessText: "",
    isTxsHash: false,
    denom: "",
    amount: "",
    toAddress: "",
    txsHash: "",
    keyword: "",
    encryptedData: "",
    mnemonicLength: 12,
    phrase: "",
    txsResponse: null,
    notifyResponse: "",
    permition: null,
    activeAccounts: [],
    activeItemIndex: 0,
    price: null,
    calculateStatus: "",
    calculateData: null,
};

const walletReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SELECTED_ROW: {
            return {
                ...state,
                selectedRow: action.payload,
            };
        }

        case actionTypes.SET_SELECTED_COIN: {
            return {
                ...state,
                selectedCoin: action.payload,
            };
        }
        case actionTypes.SET_CALCULATE: {
            return {
                ...state,
                calculateStatus: action.payload.status,
                calculateData: action.payload.transaction,
            };
        }

        case actionTypes.SET_WALLET_META: {
            return {
                ...state,
                meta: action.payload,
            };
        }

        case actionTypes.SET_ACCOUNTS: {
            return {
                ...state,
                accounts: action.payload,
            };
        }

        case actionTypes.SET_MNEMONIC: {
            return {
                ...state,
                mnemonic: action.payload,
            };
        }

        case actionTypes.GET_ACCOUNT_DETAILS: {
            return {
                ...state,
                coins: action.payload,
                coinsLoading: false,
            };
        }

        case actionTypes.GET_ACCOUNT_DETAILS_LOADING: {
            return {
                ...state,
                detailsLoading: action.payload,
            };
        }

        case actionTypes.GET_All_TRANSACTIONS_LOADING: {
            return {
                ...state,
                allTxsLoading: true,
            };
        }

        case actionTypes.SET_GRAPH_DATA: {
            return {
                ...state,
                graphDetail: action.payload,
                graphDetailLoading: false,
            };
        }
        case actionTypes.SET_GRAPH_DATA_LOADING: {
            return {
                ...state,
                graphDetailLoading: true,
            };
        }

        case actionTypes.GET_All_TRANSACTIONS: {
            return {
                ...state,
                allTxs: action.payload,
                allTxsLoading: false,
            };
        }
        case actionTypes.ACTIVE_ACCOUNTS: {
            return {
                ...state,
                activeAccounts: action.payload,
            };
        }
        case actionTypes.ACTIVE_INDEX: {
            return {
                ...state,
                activeItemIndex: action.payload,
            };
        }
        case actionTypes.CREATE_TX_LOADING: {
            return {
                ...state,
                txsLoading: true,
            };
        }

        case actionTypes.SET_ADDRESS: {
            return {
                ...state,
                address: action.payload.address,
                ecpairPriv: action.payload.ecpairPriv,
            };
        }

        case actionTypes.GET_MARKET_PRICE: {
            return {
                ...state,
                marketPrice: action.payload,
            };
        }

        case actionTypes.GET_PSIX_MARKET_PRICE: {
            return {
                ...state,
                psixMarketPrice: action.payload,
            };
        }

        case actionTypes.SEND_TXS_LOADING: {
            return {
                ...state,
                isSending: action.payload,
            };
        }

        case actionTypes.SUCCESS_BAR_CONTENT: {
            return {
                ...state,
                isSuccessOpen: action.payload.isOpen,
                successText: action.payload.message,
            };
        }

        case actionTypes.BACKUP_FILE_SUCCESS_BAR_CONTENT: {
            return {
                ...state,
                isFileSuccessOpen: action.payload.isOpen,
                fileSuccessText: action.payload.message,
            };
        }

        case actionTypes.ERROR_BAR_CONTENT: {
            return {
                ...state,
                isErrorOpen: action.payload.isOpen,
                errorText: action.payload.message,
            };
        }

        case actionTypes.CLOSE_SEND_TXS_BAR: {
            return {
                ...state,
                isSuccessOpen: action.payload,
                isErrorOpen: action.payload,
                isFileSuccessOpen: action.payload,
            };
        }

        case actionTypes.SENT_TXS_HASH: {
            return {
                ...state,
                isTxsHash: action.payload.isTxsHash,
                denom: action.payload.denom,
                amount: action.payload.amount,
                toAddress: action.payload.toAddress,
                txsHash: action.payload.txsHash,
            };
        }

        case actionTypes.SET_SEARCH_BAR_KEYWORD: {
            return {
                ...state,
                keyword: action.payload,
            };
        }

        case actionTypes.SET_ENCRYPTED_DATA: {
            return {
                ...state,
                encryptedData: action.payload,
            };
        }
        case actionTypes.SET_MNEMONIC_LENGTH: {
            return {
                ...state,
                mnemonicLength: action.payload,
            };
        }
        case actionTypes.SET_PHRASE: {
            return {
                ...state,
                phrase: action.payload,
            };
        }
        case actionTypes.GET_PRICE_DETAIL: {
            return {
                ...state,
                price: action.payload,
            };
        }
        case actionTypes.SET_TXS_RES: {
            return {
                ...state,
                txsResponse: action.payload.payload,
            };
        }
        case actionTypes.SET_PERMITION: {
            return {
                ...state,
                permition: action.payload,
            };
        }

        case actionTypes.GET_NOTIFICATION: {
            return {
                ...state,
                notifyResponse: action.payload,
            };
        }

        case actionTypes.CLEAR_STATES: {
            return {
                ...state,
                meta: {},
                mnemonic: "",
                address: "",
                accounts: [],
                transactions: [],
                contacts: [],
                coins: [],
                selectedRow: -1,
                selectedCoin: {},
                txsLoading: false,
                allTxs: {},
                graphDetail: [],
                marketPrice: {},
                psixMarketPrice: {
                    price: "",
                },
                isSending: false,
                isSuccessOpen: false,
                isErrorOpen: false,
                isFileSuccessOpen: false,
                fileSuccessText: "",
                ecpairPriv: "",
                isTxsHash: false,
                denom: "",
                amount: "",
                toAddress: "",
                txsHash: "",
                keyword: "",
                mnemonicLength: "",
                phrase: "",
                txsResponse: null,
                notifyResponse: null,
                price: null,
                // encryptedData: "",
            };
        }

        default: {
            return state;
        }
    }
};

export default walletReducer;
