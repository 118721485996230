import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  CircularProgress,
  Box,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import validate from "validate.js";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { schema } from "../../../utils/contant";
import { BackupMnemonics } from "../BackupMnemonics";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { lightTheme } from "../../../vars/theme";
import { t } from "i18next";
import { useTranslation} from "react-i18next";
// const activeTheme = localStorage.getItem("theme");

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "560px",
      backdropFilter: "blur(10px)",
      backgroundColor: " rgba(255, 255, 255, 0.13) !important",
    },
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    padding: 16,
    ":-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
      backgroundColor: "red !important",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 14px",
      backgroundColor: "transparent !important",

      "& :-webkit-autofill": {
        "-webkit-text-fill-color": "#fff !important",
      },
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px",
      margin: "0 auto",
      width: "100%",
      height: "42px",
      display: "flex",
      fontSize: 12,
      color: "#fff",
      justifyContent: "center",
      alignItems: "center",
      border: " solid 1px #fff",
      marginTop: 10,

      "& fieldset": {
        border: "none",
      },
      "&:hover fieldset": {
        border: "none ",
      },
      "& .Mui-focused fieldset": {
        border: "none ",
      },
    },
  },
  nextButtonStyle: {
    backgroundColor: " transparent !important",
    color: "#fff",
    width: "123px",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      width: "113px",
    },
  },
  passwordBox: {
    width: "100%",
    position: "relative",
    margin: "0px auto 16px auto",
  },
  eyebox: {
    width: 26,
    height: 26,
    objectFit: "contain",
    position: "absolute",
    right: 10,
    top: 18,
    cursor: "pointer",
  },
  bRed: {
    "& .MuiOutlinedInput-root": {
      border: " solid 1px red",
    },
  },
  bGray: {
    "& .MuiOutlinedInput-root": {
      border: " solid 1px #e6e6e6",
    },
  },
  button: {
    display: "flex",
    justifyContent: "flex-end",
  },
  leftLine1: {
    position: "absolute",
    width: "22.7px",
    height: "2px",
    margin: "18px -12px",
    backgroundColor: "#25c5f7",
    left: 0,
    zIndex: 1,
  },
  maskLeft1: (props) => ({
    position: "absolute",
    margin: "12px -12px",
    width: "22.7px",
    height: "16px",
    background: props.activeTheme === lightTheme ? "#eef2f5" : "#0e0515",
    left: 0,
    zIndex: 1,
  }),
  rightLine1: {
    position: "absolute",
    width: "22.7px",
    height: "2px",
    margin: "18px -12px",
    right: "0px",
    backgroundColor: "#25c5f7",
    zIndex: 1,
  },
  maskRight1: (props) => ({
    position: "absolute",
    margin: "12px -12px",
    width: "22.7px",
    height: "16px",
    right: "0px",
    zIndex: 1,
    background: props.activeTheme === lightTheme ? "#eef2f5" : "#0e0515",
  }),
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const BackupPassword = (props) => {
  const { isOpen, toggle } = props;
  const {t,li8n} =  useTranslation();
  const activeTheme = useSelector((state) => state.theme.theme);
  const classes = useStyles({ activeTheme });

  const [state, setState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    showPassword: false,
    isOpenSend: false,
  });
  const toggleSendOpen = () => {
    setState({ ...state, isOpenSend: true });
  };
  const toggleSendClose = () => {
    setState({ ...state, isOpenSend: false });
  };

  const [hasPasswordError, setPasswordError] = useState(false);

  const handleChange = (e) => {
    e.persist();
    setPasswordError(false);
    setState((state) => ({
      ...state,
      values: {
        ...state.values,
        [e.target.name]: e.target.value,
      },
      touched: {
        ...state.touched,
        [e.target.name]: true,
      },
    }));
  };
  const hasError = (field) => (state && state.errors[field] ? true : false);

  const handleClickShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    const errors = validate(state.values, schema);
    setState((state) => ({
      ...state,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [state.values]);

  return (
    <>
      <Dialog
        className={classes.root}
        open={isOpen}
        onClose={toggle}
        TransitionComponent={Transition}
        classes={{
          paperWidthSm: classes.dialogBoxWidth,
        }}
      >
        <Box className={classes.wrapper}>
          <Typography>{t("backup.Pleasewriteyourpassword")}</Typography>
          <Box className={classes.passwordBox}>
            <TextField
              type={state.showPassword ? "text" : "password"}
              className={hasPasswordError ? classes.bRed : classes.bGray}
              variant="outlined"
              name="password"
              error={hasPasswordError}
              helperText={hasPasswordError ? <>{t("backup.Invalidpassword")}</> : null}
              value={state.values.password || ""}
              placeholder={t("backup.Password")}
              fullWidth
              onChange={handleChange}
            />
            <Box
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {state.showPassword ? (
                <VisibilityIcon className={classes.eyebox} />
              ) : (
                <VisibilityOffIcon className={classes.eyebox} />
              )}
            </Box>
          </Box>
          <Box className={classes.button}>
            <Button
              className={classes.nextButtonStyle}
              onClick={toggleSendOpen}
              type="submit"
            >
          {t("backup.Unlock")}
              <span className={classes.maskLeft1} />
              <span className={classes.leftLine1} />
              <span className={classes.maskRight1} />
              <span className={classes.rightLine1} />
            </Button>
          </Box>
        </Box>
        <BackupMnemonics toggle={toggleSendClose} isOpen={state.isOpenSend} />
      </Dialog>
    </>
  );
};

export default BackupPassword;
