import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import {
    Box,
    Typography,
    Snackbar,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    FormHelperText,
    DialogTitle,
    createTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MuiAlert from "@material-ui/lab/Alert";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useDispatch } from "react-redux";
import fileEncryptionService from "../../services/fileEncryptionService";
import { login, setEncryptedData, userUnlockWallet } from "../../actions";
import cryptoService from "../../services/cryptoService";
import MnemonicPhraseModal from "../../components/MnemonicPhraseModal";
import { KeyStoreFile } from "../../components/Modal";
import Button from "../../components/Button";
import { encryptionConst } from "../../utils/contant";
import validate from "validate.js";
import arr from "../../theme/fontsConfig";
import ContinueButton from "../../components/ContinueButton";
import { lightTheme } from "../../vars/theme";
const ioCreata = require("io-creata-js");
import { useTranslation } from "react-i18next";
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const newTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 390,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: "900px",
        width: "100%",
        margin: "auto",
    },
    contentContainer: {
        maxWidth: "600px",
        margin: "auto",
        textAlign: "center",
        marginBottom: 32,
    },
    title: {
        fontFamily: "Graphik-Extralight",
        fontSize: "40px",
        fontWeight: 200,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.23,
        letterSpacing: "0.4px",
        color: theme.palette.primary.main,
        textTransform: "uppercase",
        [theme.breakpoints.down("xs")]: {
            fontSize: 25,
        },
    },
    content: {
        textAlign: "center",
        color: theme.palette.secondary.main,
        lineHeight: 2,
        marginBottom: "20px",
        [theme.breakpoints.down("xs")]: {
            textAlign: "justify",
            padding: "0px 10px",
        },
    },
    content1: {
        textAlign: "center",
        color: theme.palette.secondary.main,
        marginBottom: "20px",
        [theme.breakpoints.down("xs")]: {
            textAlign: "justify",
            padding: "0px 10px",
        },
    },
    content2: {
        color: "#29fffb",
        textAlign: "center",
        marginBottom: "20px",
    },
    btnContainer: {
        display: "flex",
        justifyContent: "space-between",
        maxWidth: "360px",
        margin: "auto",
        [newTheme.breakpoints.down("xs")]: {
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        },
    },
    titleTextColor: {
        color: "#007fc1",
        marginLeft: -10,
    },
    bRed: {
        "& .MuiOutlinedInput-root": {
            borderColor: "red",
        },
    },
    bGray: {
        "& .MuiOutlinedInput-root": {
            // border: " solid 1px #e6e6e6",
        },
    },
    horizontalLine: {
        height: 90,
        width: 2,
        backgroundColor: "#fff",
        margin: "0px 10px",
    },
    maskLeft: {
        position: "absolute",
        height: 25,

        width: 20,
        backgroundColor: "#160722",
        left: -10,
    },
    maskRight: {
        position: "absolute",
        height: 25,
        width: 20,
        backgroundColor: "#160722",
        right: -10,
    },
    text: {
        textAlign: "center",
        fontSize: 19,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.5,
        letterSpacing: "normal",
        color: "#29fffb",
        marginTop: 20,
    },
    img: {
        height: 150,
        objectFit: "contain",
        width: 150,
        margin: "10px auto",
    },
    nextButtonStyle: {
        backgroundColor: " transparent !important",
        // color: "#fff",
        width: "123px",
        position: "relative",
        [theme.breakpoints.down("xs")]: {
            width: "113px",
        },
    },
    passwordDialogBox: {
        "& .MuiDialog-paperWidthSm": {
            maxWidth: "600px",
            backgroundColor: theme.palette.primary.light,
            backdropFilter: "blur(10px)",
            overflowX: "hidden",
            padding: 10,
        },
        "& .MuiDialogActions-root": {
            padding: "0px 22px 10px",
            fontFamily: "Graphik-Extralight",
        },
        "& .MuiButton-root.Mui-disabled": {
            color: theme.palette.secondary.main,
            border: `1px solid ${theme.palette.secondary.main}`,
            opacity: 0.5,
        },
    },
    inputFieldStyle: {
        width: "100%",
        marginBottom: "23px",
        height: "52px",
        fontFamily: "Graphik-Extralight",
    },
    passwordBox: {
        width: "100%",
        position: "relative",
        margin: "0px auto 16px auto",
        position: "relative",
        " & .MuiOutlinedInput-root": {
            backgroundColor: "transparent",
            paddingLeft: 4,
        },
        "& .Mui-focused fieldset": {
            border: "none ",
        },
    },
    eyebox: {
        width: 26,
        height: 26,
        objectFit: "contain",
        position: "absolute",
        right: 16,
        top: 13,
        cursor: "pointer",
    },
    button: {
        display: "flex",
        justifyContent: "flex-end",
    },
    leftLine1: {
        position: "absolute",
        width: "22.7px",
        height: "2px",
        margin: "18px -12px",
        backgroundColor: "#25c5f7",
        left: 0,
        zIndex: 1,
    },
    maskLeft1: (props) => ({
        position: "absolute",
        margin: "12px -12px",
        width: "22.7px",
        height: "16px",
        background: props.activeTheme === lightTheme ? "#eef2f5" : "rgb(39 35 43)",
        left: 0,
        zIndex: 1,
    }),
    rightLine1: {
        position: "absolute",
        width: "22.7px",
        height: "2px",
        margin: "18px -12px",
        right: "0px",
        backgroundColor: "#25c5f7",
        zIndex: 1,
    },
    maskRight1: (props) => ({
        position: "absolute",
        margin: "12px -12px",
        width: "22.7px",
        height: "16px",
        right: "0px",
        zIndex: 1,
        background: props.activeTheme === lightTheme ? "#eef2f5" : "rgb(39 35 43)",
    }),
    leftLine2: {
        position: "absolute",
        width: "22.7px",
        height: "2px",
        margin: "25.5px -12px",
        backgroundColor: "#25c5f7",
        left: 0,
        zIndex: 1,
    },
    maskLeft2: (props) => ({
        position: "absolute",
        margin: "18.3px -12px",
        width: "22.7px",
        height: "16px",
        background: props.activeTheme === lightTheme ? "#eef2f5" : "rgb(39 35 43)",
        left: 0,
        zIndex: 1,
    }),
    maskLeft3: (props) => ({
        position: "absolute",
        margin: "18.3px -12px",
        width: "22.7px",
        height: "16px",
        background: props.activeTheme === lightTheme ? "#eef2f5" : "rgb(39 35 43)",
        left: 0,
        zIndex: 1,
    }),
    rightLine2: {
        position: "absolute",
        width: "22.7px",
        height: "2px",
        margin: "25.5px -12px",
        right: "0px",
        backgroundColor: "#25c5f7",
        zIndex: 1,
    },
    maskRight2: (props) => ({
        position: "absolute",
        margin: "18.3px -12px",
        width: "22.7px",
        height: "16px",
        right: "0px",
        zIndex: 1,
        background: props.activeTheme === lightTheme ? "#eef2f5" : "rgb(39 35 43)",
    }),
}));
const schema = (t) => {
    return {
        password: {
            presence: true,
            format: {
                pattern:
                    /(?=^.{8,}$)(?=.*\d)(?=.*[!$%^&()_+|~=`{}\[\]:";'<>?,.#@*-\/\\]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
                message: `${t("validationmessage.password")}`,
            },
        },
        confirmPassword: {
            presence: true,
            format: {
                pattern:
                    /(?=^.{8,}$)(?=.*\d)(?=.*[!$%^&()_+|~=`{}\[\]:";'<>?,.#@*-\/\\]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
            },
            equality: {
                attribute: "password",
                message: `{t('validationmessage.confirmpassword')}`,
                comparator: function (v1, v2) {
                    return JSON.stringify(v1) === JSON.stringify(v2);
                },
            },
        },
    };
};

const AccessMyWallet = (props) => {
    const { t, li8n } = useTranslation();
    const activeTheme = useSelector((state) => state.theme.theme);
    const classes = useStyles({ activeTheme });
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState({
        isOpen: false,
        isError: false,
        isInvalid: false,
        isCancel: false,
        isOpenReceive: false,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [file, setFile] = useState("");
    const [password, setPassword] = useState("");

    const [fileName, setSelectedFileName] = useState("");

    const unlockWalletFile = async (file, password) => {
        await setIsLoading(true);
        try {
            const tempArray = [];
            const { crypto, meta } = JSON.parse(file);
            const key = fileEncryptionService.createEncryptionKey(password);
            const decryptedDataJSON = fileEncryptionService.decryptData(crypto.cipherText, key);
            const { accounts, mnemonic, contacts } = JSON.parse(decryptedDataJSON);
            setIsLoading(false);
            dispatch(setEncryptedData(crypto.cipherText));
            if (accounts.length > 1) {
                dispatch(userUnlockWallet(meta, accounts, ""));
            } else {
                dispatch(userUnlockWallet(meta, tempArray, ""));
            }
            dispatch(login());

            const chainId = process.env.REACT_APP_CHAIN_ID;
            const creata = ioCreata.network(
                `${process.env.REACT_APP_CREATACHAIN_IOCREATA_URL}`,
                chainId,
            );
            creata.setPath("m/44'/118'/0'/0/0"); //hd path for creata
            const address = creata.getAddress(mnemonic);
            tempArray.push({
                displayName: `${accounts[0].displayName} `,
                path: `${accounts[0].path}`,
                address: `${address}`,
                privateKey: {},
                secretKey: {},
            });

            await dispatch({
                type: "SET_ADDRESS",
                payload: { address: address, ecpairPriv: "" },
            });

            history.push("/dashboard");
            return { error: null, accounts, mnemonic, meta, contacts };
        } catch (error) {
            setIsLoading(false);
            setState({
                ...state,
                isOpen: true,
                isError: true,
                isInvalid: false,
                isCancel: false,
            });
            return {
                error,
                accounts: null,
                mnemonic: null,
                meta: null,
                contacts: null,
            };
        }
    };

    const handleClose = () => {
        setFile("");
        setSelectedFileName("");
        setState({
            ...state,
            isOpen: true,
            isError: false,
            isInvalid: false,
            isCancel: false,
        });
        setIsModalOpen(false);
        setPassword("");
        setIsModal(false);
        setPasswordError(false);
        setIsPassword({
            ...isPassword,
            password: "",
            confirmPassword: "",
            touched: {
                password: false,
                confirmPassword: false,
            },
        });
    };

    // const handleCloseSnackbar = () => {
    //   setState({
    //     ...state,
    //     isOpen: false,
    //     isError: false,
    //     isInvalid: false,
    //     isCancel: false,
    //   });
    // };

    const handleChange = (e) => {
        e.persist();
        const { value } = e.target;
        setPassword(value);
    };

    const submitPassword = (e) => {
        e.preventDefault();
        unlockWalletFile(file, password);
    };

    const keystoreFileHandler = async (e) => {
        const { files } = e.target;

        if (files.length > 0) {
            setSelectedFileName(files[0].name);
            const reader = new FileReader();
            reader.readAsText(files[0]);
            reader.onload = (e) => setFile(e.target.result);
            // reader.onerror = (e) => console.log(e);
            // await setIsModalOpen(true);
        }
    };
    const [hasPasswordError, setPasswordError] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [isPassword, setIsPassword] = useState({
        password: "",
        confirmPassword: "",
        touched: {
            password: false,
            confirmPassword: false,
        },
    });
    const [showState, setShowState] = React.useState({
        showPasswords: false,
        showPassword: false,
    });
    // const handleChange1 = (e) => {
    //     e.persist();
    //     setPasswordError(false);
    //     setPassword(e.target.value);
    // };

    const handleClickShowPassword = () => {
        setShowState({ ...showState, showPassword: !showState.showPassword });
    };

    const handleChange1 = (e) => {
        e.persist();
        setIsPassword({
            ...isPassword,
            touched: { ...isPassword.touched, [e.target.name]: true },
            [e.target.name]: e.target.value,
        });
    };
    let errors = validate(
        {
            password: isPassword.password,
            confirmPassword: isPassword.confirmPassword,
        },
        schema(t),
    );
    errors = errors || {};

    const handleClickShowPassword1 = () => {
        setShowState({ ...showState, showPasswords: !showState.showPasswords });
    };
    const showMnemonic = async (e) => {
        e.preventDefault();
        const { password, confirmPassword } = isPassword;
        if (password && confirmPassword != undefined) {
            setIsModal(false);
            setIsPassword({
                ...isPassword,
                touched: {
                    password: true,
                    confirmPassword: true,
                },
            });
            // setIsMnemonicModalOpen(true);
            history.push({
                pathname: "/access-my-wallet-type",
                password: isPassword.password,
            });
        }
    };

    // const getData = localStorage.getItem("encrypted");
    // const { encryptedData } = useSelector((state) => state.wallet);
    // const showMnemonic = async (e) => {
    //     e.preventDefault();
    //     setIsMnemonicModalOpen(true);
    //     setIsModal(false);
    //     // try {
    //     //     // txManager.setAccounts({ accounts: dataToEncrypt.accounts });
    //     //     const key = fileEncryptionService.createEncryptionKey(password);
    //     //     const decryptedDataJSON = fileEncryptionService.decryptData(encryptedData, key);
    //     //     const { mnemonic } = JSON.parse(decryptedDataJSON);
    //     //     setIsMnemonicModalOpen(true);
    //     //     setIsModal(false);
    //     // } catch (error) {
    //     //     setPasswordError(true);
    //     // }
    // };

    return (
        <>
            {/* <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={state.isOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}>
                {state.isError ? (
                    <Alert onClose={handleCloseSnackbar} severity="error">
                        Invalid file and password
                    </Alert>
                ) : state.isInvalid ? (
                    <Alert onClose={handleCloseSnackbar} severity="error">
                        Mnemonic phrase not matched
                    </Alert>
                ) : state.isCancel ? (
                    <Alert onClose={handleCloseSnackbar} severity="error">
                        User denied for authentication
                    </Alert>
                ) : null}
            </Snackbar> */}

            <Box className={classes.root}>
                <Box className={classes.contentContainer}>
                    <span className={classes.title}>
                        {t("accessmywallet.accessyour")} &nbsp;
                        <span className={classes.titleTextColor}>
                            {t("accessmywallet.webwallet")}
                        </span>{" "}
                        {t("accessmywallet.Anytime")}
                    </span>
                </Box>
                <Typography className={classes.content1} variant="subtitle2" gutterBottom>
                    {t("accessmywallet.mainhead")}
                </Typography>
                {/* <Typography className={classes.content2} variant="subtitle2" gutterBottom>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                   
                    no sea takimata sanctus est Lorem
                </Typography> */}
                <div className={classes.btnContainer}>
                    <Button
                        title={t("accessmywallet.KeystoreFile")}
                        maxWidth={"160px"}
                        background={"red"}
                        style={{ marginRight: 10 }}
                        onClick={() => setIsModalOpen(true)}
                    />

                    <Button
                        title={t("accessmywallet.MnemonicPhrase")}
                        maxWidth={"160px"}
                        onClick={() => setIsModal(true)}
                    />
                </div>
                <KeyStoreFile
                    toggle={handleClose}
                    isOpen={isModalOpen}
                    file={file}
                    fileName={fileName}
                    onChange={handleChange}
                    name="password"
                    value={password || ""}
                    isLoading={isLoading}
                    keystoreFileHandler={keystoreFileHandler}
                    submitPassword={submitPassword}
                    error={state.isError}
                />
                <Dialog
                    open={isModal}
                    keepMounted
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                    className={classes.passwordDialogBox}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        <Typography style={{ fontSize: 18, lineHeight: 1 }} color="secondary">
                            {t("accessmywallet.Accessviamnemonic")}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <form onSubmit={showMnemonic}>
                            <Typography
                                variant="body1"
                                style={{ fontSize: 12, margin: "0px 0px 16px 0px" }}
                                className={classes.content}>
                                {t("accessmywallet.passwordsecure")}
                            </Typography>
                            <Box className={classes.passwordBox}>
                                <TextField
                                    type={showState.showPasswords ? "text" : "password"}
                                    className={
                                        isPassword.touched.password && errors.password
                                            ? classes.bRed
                                            : classes.bGray
                                    }
                                    variant="outlined"
                                    name="password"
                                    // error={isPassword.touched.password && errors.password}
                                    // helperText={state.password ? "Invalid Password" : null}
                                    value={isPassword.password || ""}
                                    placeholder={t("accessmywallet.Password")}
                                    fullWidth
                                    onChange={handleChange1}
                                    multiline={false}
                                    autoFocus
                                />
                                <span className={classes.maskLeft3} />
                                <span className={classes.leftLine2} />
                                <span className={classes.maskRight2} />
                                <span className={classes.rightLine2} />
                                <FormHelperText
                                    style={{ color: "red", padding: "6px 2px", fontWeight: 700 }}>
                                    {isPassword.touched.password && errors.password ? (
                                        <>{t("validationmessage.password")} </>
                                    ) : null}
                                </FormHelperText>
                                <Box onClick={handleClickShowPassword1}>
                                    {showState.showPasswords ? (
                                        <VisibilityIcon className={classes.eyebox} />
                                    ) : (
                                        <VisibilityOffIcon className={classes.eyebox} />
                                    )}
                                </Box>
                            </Box>
                            <Box className={classes.passwordBox}>
                                <TextField
                                    type={showState.showPassword ? "text" : "password"}
                                    className={
                                        isPassword.touched.confirmPassword && errors.confirmPassword
                                            ? classes.bRed
                                            : classes.bGray
                                    }
                                    variant="outlined"
                                    name="confirmPassword"
                                    // error={
                                    //     isPassword.touched.confirmPassword && errors.confirmPassword
                                    // }
                                    // helperText={state.password ? "Invalid Password" : null}
                                    value={isPassword.confirmPassword || ""}
                                    placeholder={t("accessmywallet.ConfirmPassword")}
                                    fullWidth
                                    onChange={handleChange1}
                                />
                                <span className={classes.maskLeft2} />
                                <span className={classes.leftLine2} />
                                <span className={classes.maskRight2} />
                                <span className={classes.rightLine2} />
                                <FormHelperText
                                    style={{ color: "red", padding: "6px 2px", fontWeight: 700 }}>
                                    {isPassword.touched.confirmPassword &&
                                    errors.confirmPassword ? (
                                        <>{t("accessmywallet.passwordisnotmatched")}</>
                                    ) : null}
                                </FormHelperText>

                                <Box onClick={handleClickShowPassword}>
                                    {showState.showPassword ? (
                                        <VisibilityIcon className={classes.eyebox} />
                                    ) : (
                                        <VisibilityOffIcon className={classes.eyebox} />
                                    )}
                                </Box>
                            </Box>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Box className={classes.button}>
                            <ContinueButton
                                title={t("accessmywallet.Continue")}
                                maxWidth={"150px"}
                                disabled={isPassword.confirmPassword != isPassword.password}
                                onClick={showMnemonic}
                                // disabled={errors.password}

                                type="submit"
                            />
                        </Box>
                    </DialogActions>
                </Dialog>
            </Box>
        </>
    );
};

export function mapStateToProps(state) {
    return {};
}

const mapDispatchToProps = {
    login,
    userUnlockWallet,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessMyWallet);
