import * as actionTypes from "../actions";

const initialState = {
  on: false,
  time: null,
};

const autoLockReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOCK_ON: {
      return {
        ...state,
        on: true,
      };
    }

    case actionTypes.LOCK_OFF: {
      return {
        ...state,
        on: false,
      };
    }

    case actionTypes.SET_TIME: {
      return {
        ...state,
        time: action.payload,
      };
    }
    // case actionTypes.CLEAR_STATES: {
    //   return {
    //     ...state,
    //     data: [],
    //   };
    // }

    default: {
      return state;
    }
  }
};

export default autoLockReducer;
