import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { lightTheme } from "../../vars/theme";
import { useSelector } from "react-redux";

// const activeTheme = localStorage.getItem("theme");

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",

    "& .MuiButton-root.Mui-disabled": {
      color: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.secondary.main}`,
      opacity: 0.5,
    },
    "& .MuiButton-label": {
      fontFamily: "Graphik-Extralight",
      fontWeight: 200,
      fontStretch: "normal",
      fontStyle: "normal",

      letterSpacing: "normal",
    },
  },
  leftLine: {
    position: "absolute",
    width: "22.7px",
    height: "2px",
    margin: "19px -12px",
    backgroundColor: "#25c5f7",
    zIndex: 1,
  },
  maskLeft: (props) => ({
    position: "absolute",
    margin: "12px -12px",
    width: "22.7px",
    height: "16px",
    background: props.activeTheme === lightTheme ? "#eef2f5" : "rgb(39 35 42)",
    zIndex: 1,
  }),
  rightLine: {
    position: "absolute",
    width: "22.7px",
    height: "2px",
    margin: "19px -12px",
    right: "0px",
    backgroundColor: "#25c5f7",
    zIndex: 1,
  },
  maskRight: (props) => ({
    position: "absolute",
    margin: "12px -12px",
    width: "22.7px",
    height: "16px",
    right: "0px",
    zIndex: 1,
    background: props.activeTheme === lightTheme ? "#eef2f5" : "rgb(39 35 42)",
    // @media (max-width: 768px) {
    //   background: linear-gradient(90deg, #cdbacd 50%, #c49fc4 50%),
    // }
    // @media (max-width: 580px) {
    //   background: linear-gradient(90deg, #9462cd 50%, #4d3b93 50%),
    // }
  }),
}));

export default function ContainedButtons(props) {
  const {
    title,
    maxWidth,
    onClick,
    type,
    disabled,
    maskLeftStyle,
    maskRightStyle,
  } = props;
  const activeTheme = useSelector((state) => state.theme.theme);
  const classes = useStyles({ activeTheme });
  return (
    <div className={classes.root}>
      <span className={classes.maskLeft} style={maskLeftStyle} />
      <span className={classes.leftLine} />
      <Button
        variant="outlined"
        color="secondary"
        style={{ width: `${maxWidth}` }}
        onClick={onClick}
        type={type}
        disabled={disabled}
      >
        {title}
      </Button>
      <span className={classes.maskRight} style={maskRightStyle} />
      <span className={classes.rightLine} />
    </div>
  );
}
