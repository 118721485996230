import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import i18n from "../i18n";
import English from "../assets/images/en.png";
import Korean from "../assets/images/kr.png";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const breakpoints = createBreakpoints({});
const theme = createTheme({
  overrides: {
    MuiInput: {
      formControl: {
        "label + &": {
          marginTop: "0",
        },
      },
    },
    MuiInputBase: {
      root: {
        width: "35px",
        lineHeight: ".4rem",
      },
      input: {
        width: "0",
        padding: "9px 7px  3px",
        [breakpoints.down("480")]: {
          padding: "9px 14px  3px",
        },
      },
    },
    MuiSelect: {
      select: {
        minWidth: "25px",
        paddingRight: "0 !important",
        "&:focus": {
          backgroundColor: "transparent",
        },
      },
      selectMenu: {
        //  color: 'transparent',
        fontSize: "0",
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: "12px",
        fontFamily: "Graphik-Regular",
        fontWeight: "400",
        [breakpoints.down("630")]: {
          fontSize: "12px",
        },
        [breakpoints.down("600")]: {
          minHeight: "30px",
        },
      },
    },
    MuiSvgIcon: {
      root: {
        display: "none",
      },
    },
    MuiListItem: {
      root: {
        justifyContent: "space-between",
      },
      gutters: {
        paddingLeft: "10px",
        paddingRight: "10px",
      },
    },

    MuiList: {
      padding: {
        paddingTop: "0",
        paddingBottom: "0",
      },
    },

    MuiPopover: {
      paper: {
        width: "95px",
        minHeight: "73px",
        top: "68px !important",
        //   left: "690px !important",
        [breakpoints.down("630")]: {
          width: "90px",
          top: "55px !important",
        },
        [breakpoints.down("600")]: {
          minHeight: "60px",
        },
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    minWidth: 35,
  },
  flagImage: {
    width: "25px",
    height: "25px",
    borderRadius: "50%",
    [breakpoints.down("630")]: {
      width: "22px",
      height: "22px",
    },
    [breakpoints.down("480")]: {
      width: "18px",
      height: "18px",
    },
  },
}));

const countries = [
  {
    label: "English",
    src: English,
    link: "en",
    value: "en",
  },
  {
    label: "Korean",
    src: Korean,
    link: "ko",
    value: "ko",
  },
];

const CountryDrop = () => {
  const classes = useStyles();
  let defaultValue = localStorage.getItem("lang") || "en";

  const [open, setOpen] = useState(false);
  const [selectValue, setSelectValue] = useState(defaultValue);

  const handleChange = (event) => {
    i18n.changeLanguage(event.target.value);
    localStorage.setItem("lang", event.target.value);
    setSelectValue(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <form autoComplete="off">
        <FormControl className={classes.formControl}>
          <Select
            className={classes.input}
            open={open}
            disableUnderline={true}
            onClose={handleClose}
            onOpen={handleOpen}
            defaultValue="en"
            value={selectValue}
            onChange={handleChange}
            inputProps={{
              id: "open-select",
            }}
          >
            {countries.map((option, key) => (
              <MenuItem value={option.value} key={key}>
                <Avatar
                  className={classes.flagImage}
                  src={option.src}
                  alt={option.value}
                ></Avatar>

                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </form>
    </MuiThemeProvider>
  );
};

export default CountryDrop;
