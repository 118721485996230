const ioCreata = require("io-creata-js");

const chainId = process.env.REACT_APP_CHAIN_ID;
const creata = ioCreata.network(
  `${process.env.REACT_APP_CREATACHAIN_IOCREATA_URL}`,
  chainId
);
creata.setPath("m/44'/118'/0'/0/0"); //hd path for creata

module.exports = creata;
