import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { useSelector } from "react-redux";
import { hourglass } from "../../assets/images";
import { lightTheme } from "./../../vars/theme";
import Button from "../Button";

const useStyles = makeStyles((theme) => ({
  dialogBoxWidth: (props) => ({
    width: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
    backgroundColor:
      props.activeTheme == lightTheme ? " rgb(238 242 245)" : "#000",
  }),
  mainBox: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "flex-end",
    width: "410px",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "300px",
    },
  },
  title: {
    fontFamily: "Graphik-Extralight",
    fontSize: "30px",
    fontWeight: "normal",
    fontWeight: 200,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.23,
    letterSpacing: "2px",
    textAlign: "center",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    margin: "38px 0px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
    },
  },
  subTitle: (props) => ({
    color: "#007fc1",
    fontFamily: "Graphik-Regular",
  }),

  buttonBox: {
    marginTop: "20px",
  },
  button: {
    display: "flex",
    justifyContent: "flex-end",
  },

  hourglass: {
    width: "170px",
    height: "170px",
    cursor: "pointer",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const SessionTimeoutModal = (props) => {
  const { open, handleClose } = props;
  const activeTheme = useSelector((state) => state.theme.theme);
  const { t } = useTranslation();
  const classes = useStyles({ activeTheme });

  return (
    <>
      <div className={classes.root}>
        <Dialog
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          classes={{
            paperWidthSm: classes.dialogBoxWidth,
          }}
        >
          <Box className={classes.mainBox}>
            <img
              src={hourglass}
              alt="hour glass"
              className={classes.hourglass}
            />
            <Typography className={classes.title}>
              {" "}
              {t("auth.sessiontime")}{" "}
              <span className={classes.subTitle}>{t("auth.logout")}.</span>
            </Typography>

            <Box className={classes.button}>
              <Button
                title={t("auth.Gotit")}
                maxWidth={"170px"}
                type="submit"
                onClick={handleClose}
              />
            </Box>
          </Box>
        </Dialog>
      </div>
    </>
  );
};
export default SessionTimeoutModal;
