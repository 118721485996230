import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";

import AppRoutes from "./routes";
import "./App.css";
import { getThemeByName } from "./theme/base";
import { darkTheme, lightTheme } from "./vars/theme";
import { handleChangeTheme } from "./actions/themeActions";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notificationAlert, setPermition } from "./actions";
import { useTranslation } from "react-i18next";
import SessionTimeoutModal from "./components/SessionLogoutModal/SessionLogoutModal";
import { logout, modalclose, modalopen, socketDisconnect } from "./actions";
import { showNotification } from "./utils/notification";

function noop() {}
(() => {
  if (

    process.env.NODE_ENV !== "production"
  ) {
    console.log = noop;
    console.warn = noop;
    console.error = noop;
  }
})();
const App = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { permition, notifyResponse, address } = useSelector(
    (state) => state.wallet
  );

  const { loggedIn } = useSelector((state) => state.session);
  const activeTheme = useSelector((state) => state.theme.theme);
  const isOpen = useSelector((state) => state.session.open);
  const isAuthenticate = useSelector((state) => state.session.loggedIn);
  const time = useSelector((state) => state.lock.time);
  const check = useSelector((state) => state.notify.notifyCheck);
  const [theme, setTheme] = useState(getThemeByName(lightTheme));

  // open session logout modal
  const handleClose = () => {
    dispatch(modalclose());
    dispatch(socketDisconnect());
    dispatch(logout());
    dispatch({ type: "CLEAR_STATES" });
  };

  useEffect(() => {
    if (time != null && isAuthenticate) {
      const timer = setTimeout(() => {
        dispatch(modalopen());
      }, time * 60000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [time, isAuthenticate]);

  // Retrieve the theme object by theme name
  useEffect(() => {
    const element = document.getElementsByClassName("App");
    element[0].style.background =
      activeTheme == lightTheme
        ? "linear-gradient(90deg, rgba(220,221,224,1) 0%, rgba(238,240,245,1) 100%, rgba(0,212,255,1) 100%)"
        : "linear-gradient(90deg, #160723 50%, #160723 50%)";
    setTheme(getThemeByName(activeTheme));
  }, [activeTheme]);

  useEffect(() => {
    const local = localStorage.getItem("theme");
    local === "darkTheme"
      ? dispatch(handleChangeTheme(darkTheme))
      : dispatch(handleChangeTheme(lightTheme));
  }, []);

  useEffect(() => {
    if (loggedIn) {
      const notificationEnable = async () => {
        dispatch(notificationAlert(check));
        const notify = await Notification.requestPermission();

        if (notify == "granted") {
          dispatch(notificationAlert(true));
          dispatch(setPermition("granted"));
        } else {
          dispatch(notificationAlert(false));
          dispatch(setPermition("denied"));
        }
      };
      notificationEnable();
    }
  }, [loggedIn]);

  useEffect(() => {
    if (notifyResponse !== null) {
      const notificationType =
        notifyResponse.type === "self"
          ? `${t("history.TransactionSent")}`
          : notifyResponse.type === "recieved"
          ? `${t("history.TransactionReceive")}`
          : notifyResponse.type === "send"
          ? `${t("history.TransactionSent")}`
          : null;
      const hash = notifyResponse.hash;
      var options = {
        body: `${hash}`,
        icon: "notify.svg",
      };
      if (hash && notificationType)
        showNotification(notificationType, permition, options, hash);
    }
  }, [notifyResponse]);

  // useEffect(() => {
  //   let render = true;
  //   setTimeout(() => {
  //     if (status.time == 1000) {
  //       showNotification(
  //         status.send.notificationType,
  //         permition,
  //         status.send.options,
  //         status.send.txId
  //       );
  //     } else if (status.time == 2000) {
  //       showNotification(
  //         status.recieve.notificationType,
  //         permition,
  //         status.recieve.options,
  //         status.recieve.txId
  //       );
  //     } else {
  //       showNotification(
  //         status.failed.notificationType,
  //         permition,
  //         status.failed.options,
  //         status.failed.txId
  //       );
  //     }
  //   }, status.time);
  //   return () => {
  //     render = false;
  //   };
  // }, []);

  return (
    <div className="App">
      <>
        <ToastContainer
          position="top-center"
          hideProgressBar={true}
          transition={Slide}
          autoClose={3000}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
          className="toast-container"
        />
      </>
      <ThemeProvider theme={theme}>
        <AppRoutes />
        <SessionTimeoutModal open={isOpen} handleClose={handleClose} />
      </ThemeProvider>
    </div>
  );
};

export default App;
