import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Box, Typography, Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { useHistory } from "react-router-dom";
import BackButton from "../../BackButton";
import { restoreFile } from "../../../assets/images";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  // root: {
  //     margin: "0 auto",
  //     padding: "0 20px 0px 14px",
  //     "& .MuiContainer-root": {
  //         maxWidth: 560,
  //     },
  //     "& .MuiDialog-paper": {
  //         border: "solid 1px rgba(255, 255, 255, 0.13)",
  //         backgroundColor: " rgba(255, 255, 255, 0.13) !important",
  //         backdropFilter: "blur(20px)",
  //     },
  // },

  dialogBoxWidth: {
    maxWidth: "508px",
    padding: "10px 12px",
    border: "solid 1px transparent",
    backgroundColor: " rgba(255, 255, 255, 0.13) !important",
    backdropFilter: "blur(10px)",
    borderRadius: 0,
  },
  main: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
  },
  title: {
    fontFamily: "Graphik-ExtraLight",
    fontSize: "24px",
    fontWeight: "normal",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.23,
    letterSpacing: "2px",
    textAlign: "center",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
  },
  drag: {
    color: theme.palette.primary.main,
    border: "1px solid #fff",
    height: 100,
    width: "100%",
    maxWidth: "90%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px auto",
    position: "relative",
  },
  leftLine: {
    position: "absolute",
    width: "22.7px",
    height: "2px",
    margin: "22px -12px",
    backgroundColor: "#25c5f7",
    left: 0,
    zIndex: 1,
  },
  maskLeft: {
    position: "absolute",
    margin: "15px -12px",
    left: 0,
    width: "22.7px",
    height: "16px",
    backgroundColor: " rgb(45 40 52)",
    zIndex: 1,
  },
  rightLine: {
    position: "absolute",
    width: "22.7px",
    height: "2px",
    margin: "-25px -12px",
    right: "0px",
    backgroundColor: "#25c5f7",
    zIndex: 1,
  },
  maskRight: {
    position: "absolute",
    margin: "-32px -12px",
    width: "22.7px",
    height: "16px",
    right: "0px",
    zIndex: 1,
    backgroundColor: " rgb(45 40 52)",
  },
  buttonBox: {
    position: "relative",
    display: "flex",
    width: "90%",
    justifyContent: "space-between",
    margin: "20px auto",
  },

  buttonNext: {
    padding: "6px 40px",
    position: "relative",
  },
  imgRestor: {
    margin: "20px auto 40px auto",
  },
  inputField: {
    color: "#fff ",
    padding: "0px 20px",
    border: "1px solid transparent",
    backgroundColor: "transparent",
    width: "100%",
    height: "100%",
    outline: "none",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const MnemonicRestore = (props) => {
  const { t, li8n } = useTranslation();
  const { isOpen, toggle } = props;
  const history = useHistory();
  const classes = useStyles();
  const fileElem = useRef(null);
  return (
    <div className={classes.root}>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Dialog
              className={classes.root}
              open={isOpen}
              onClose={toggle}
              TransitionComponent={Transition}
              classes={{
                paperWidthSm: classes.dialogBoxWidth,
              }}
            >
              <Box className={classes.main}>
                <img
                  src={restoreFile}
                  alt="restoreImg"
                  className={classes.imgRestor}
                />
                <Typography className={classes.title}>
                  {t("mnemonicRestore.RestoreyourWallet")}
                  <br /> {t("mnemonicRestore.ViaMnemonicPhrase")}
                </Typography>
                <Box
                  className={classes.drag}
                  onClick={() => {
                    fileElem.current && fileElem.current.click();
                  }}
                >
                  <input type="text" className={classes.inputField} />
                  <span className={classes.maskLeft} />
                  <span className={classes.leftLine} />
                  <span className={classes.maskRight} />
                  <span className={classes.rightLine} />
                </Box>

                <Box className={classes.buttonBox}>
                  <BackButton
                    title={t("mnemonicRestore.Back")}
                    maxWidth={"150px"}
                    onClick={toggle}
                  />

                  <Button variant="contained" className={classes.buttonNext}>
                    {t("mnemonicRestore.RestoreWallet")}
                    <span className={classes.maskLeft} />
                    <span className={classes.leftLine} />
                    <span className={classes.maskRight} />
                    <span className={classes.rightLine} />
                  </Button>
                </Box>
              </Box>
            </Dialog>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default MnemonicRestore;
