import * as actionTypes from "../actions";

const initialState = {
  loggedIn: false,
  data: [],
  open: false,
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SESSION_LOGIN: {
      return {
        ...state,
        loggedIn: true,
      };
    }

    case actionTypes.SESSION_LOGOUT: {
      return {
        ...state,
        loggedIn: false,
      };
    }

    case actionTypes.MNEMONIC_WORDS: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case actionTypes.CLEAR_STATES: {
      return {
        ...state,
        data: [],
      };
    }
    case actionTypes.SESSION_OPEN: {
      return {
        ...state,
        open: true,
      };
    }
    case actionTypes.SESSION_CLOSE: {
      return {
        ...state,
        open: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default sessionReducer;
