import palette from "./palette";

export default {
    h1: {
        fontFamily: "Graphik-Extralight",
        color: palette.text.primary,
        fontWeight: 500,
        fontSize: "40px",
        lineHeight: "40px",
        // fontWeight: 200,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.23,
        letterSpacing: "0.4px",
        textTransform: "uppercase",
    },
    h2: {
        fontFamily: "Graphik-Extralight",
        color: palette.text.primary,
        fontWeight: 500,
        fontSize: "29px",
        letterSpacing: "-0.24px",
        lineHeight: "32px",
    },
    h3: {
        fontFamily: "Graphik-Extralight",
        color: palette.text.primary,
        fontWeight: 500,
        fontSize: "24px",
        letterSpacing: "-0.06px",
        lineHeight: 1.5,
    },
    h4: {
        fontFamily: "Graphik-Extralight",
        color: palette.text.primary,
        fontWeight: 500,
        fontSize: "18px",
        letterSpacing: "normal",
        lineHeight: 1.5,
    },
    h5: {
        fontFamily: "Graphik-Extralight",

        color: palette.text.light,
        fontWeight: 500,
        fontSize: "14px",
        letterSpacing: "-0.05px",
        fontStyle: "normal",
        lineHeight: 2.14,
    },
    h6: {
        fontFamily: "Graphik-Extralight",

        color: palette.text.primary,
        fontWeight: 500,
        fontSize: "12px",
        letterSpacing: "normal",
        lineHeight: 1.5,
    },
    subtitle1: {
        fontFamily: "Graphik-Extralight",

        color: palette.text.primary,
        fontSize: "16px",
        lineHeight: 3.31,
        fontWeight: 300,
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
    },
    subtitle2: {
        fontFamily: "Graphik-Light",
        fontWeight: 300,
        color: palette.text.primary,
        fontWeight: "normal",
        fontSize: "15px",
        letterSpacing: "normal",
        lineHeight: 1.62,
    },
    body1: {
        fontFamily: "Graphik-Extralight",

        color: palette.text.primary,
        fontSize: "14px",
        letterSpacing: "-0.05px",
        lineHeight: 3.79,
        fontWeight: 300,
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "0.69px",
    },
    body2: {
        fontFamily: "Graphik-Extralight",
        color: palette.text.secondary,
        fontSize: "12px",
        letterSpacing: "-0.04px",
        lineHeight: "18px",
    },
    button: {
        fontFamily: "Graphik-Light",
        color: palette.text.primary,
        fontSize: "14px",
    },
    caption: {
        fontFamily: "Graphik-Extralight",
        color: palette.text.secondary,
        fontSize: "11px",
        letterSpacing: "0.33px",
        lineHeight: "13px",
    },
    overline: {
        fontFamily: "Graphik-Extralight",
        color: palette.text.secondary,
        fontSize: "11px",
        fontWeight: 500,
        letterSpacing: "normal",
        textTransform: "captialize",
    },
};
