import { combineReducers } from "redux";

import sessionReducer from "./sessionReducer";
import walletReducer from "./walletReducer";
import themeReducer from "./themeReducer";
import autoLockReducer from "./autoLockReducer";
import notificationReducer from "./notificationReducer";

const rootReducer = combineReducers({
  session: sessionReducer,
  wallet: walletReducer,
  theme: themeReducer,
  lock: autoLockReducer,
  notify: notificationReducer,
});

export default rootReducer;
