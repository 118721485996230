import palette from "../palette";

export default {
  root: {
    backgroundColor: palette.primary.main,
    color: palette.white,
    borderRadius: "unset",
  },
  label: {
    paddingLeft: "unset",
    paddingRight: "unset",
  },
  deleteIcon: {
    display: "none",
    color: palette.white,
    height: "18px",
    width: "18px",
    "&:hover": {
      color: palette.white,
    },
    margin: "unset",
  },
  deletable: {
    "&:focus": {
      backgroundColor: "#ffffff26",
    },
  },
  clickable: {
    "&:hover": {
      backgroundColor: "#ffffff26",
    },
    "&:active": {
      backgroundColor: "#ffffff26",
    },
    "&:focus": {
      backgroundColor: "#ffffff26",
    },
  },
};
