import { applyMiddleware, createStore, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { composeWithDevTools } from "redux-devtools-extension";
// import { createLogger } from 'redux-logger';

import rootReducer from "../reducers";

const persistConfig = {
  // Root
  key: "root",
  // Storage Method (React Native)
  storage,
  timeout: null,

  // Whitelist (Save Specific Reducers)
  whitelist: ["lock", "notify"],
  blacklist: [
    // "lock",
    // 'auth',
    // 'myCart',
    // 'profile',
    // 'home',
    // 'orders',
    // 'notifications',
    // 'departments',
    // 'search',
    // 'addresses',
    // Blacklist (Don't Save Specific Reducers)
  ],
};

// const loggerMiddleware = createLogger();

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configureStore(preloadedState = {}) {
  const middlewares = [thunkMiddleware]; // loggerMiddleware
  const middlewareEnhancer =
    process.env.NODE_ENV === "development"
      ? composeWithDevTools(applyMiddleware(...middlewares))
      : applyMiddleware(...middlewares);

  const composeEnhancers = compose;
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

  const enhancer = composeEnhancers(
    applyMiddleware(...middlewares)
    // other store enhancers if any
  );

  const store = createStore(
    persistedReducer,
    preloadedState,
    middlewareEnhancer
  );

  let persistor = persistStore(store);

  return { store, persistor };
}
