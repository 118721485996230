import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { leftarrow } from "../../assets/images";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    borderLeft: "unset",
    "& .MuiButton-label": {
      fontFamily: "Graphik-Regular",
      fontWeight: 200,
      fontStretch: "normal",
      fontStyle: "normal",

      letterSpacing: "normal",
    },
    "& .MuiButton-outlinedSecondary:hover": {
      borderLeft: "unset !important",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 5
    }
  },

  btnContainer: {
    position: "relative",
    width: 160,
    margin: "0 auto",
    "& .MuiButton-outlinedSecondary:hover": {
      borderLeft: "unset !important",
    },
  },
  arrowIcon: {
    position: "absolute",
    top: 14,
    left: -8,
    width: "22.7px",
    height: "16px",
  },
}));

export default function ContainedButtons(props) {
  const { title, maxWidth, onClick, type, disabled, to, marginRight } = props;
  const classes = useStyles();

  return (
    <>
      <div className={classes.btnContainer}>
        <img src={leftarrow} alt="arrow" className={classes.arrowIcon} />
        <Button
          className={classes.root}
          variant="outlined"
          color="secondary"
          style={{ width: `${maxWidth}`, marginRight: `${marginRight}px` }}
          onClick={onClick}
          type={type}
          disabled={disabled}
        >
          {title}
        </Button>
      </div>
    </>
  );
}
