import { colors } from "@material-ui/core";

const white = "#FFFFFF";
const black = "#090909";
const themeBackgroundColor = "#f8f8fb";
const themePrimaryColor = "#007fc1";
const themeLightTextColor = "#092326";
const error = "#e53935";

export default {
  black,
  white,
  primary: {
    background: themeBackgroundColor,
    contrastText: themePrimaryColor,
    dark: "#25c5f7",
    main: themePrimaryColor,
    light: "rgb(238 242 245)",
    darklight:black,
  },
  secondary: {
    contrastText: themePrimaryColor,
    dark: themePrimaryColor,
    main: black,
    light: themePrimaryColor,
  },
  error: {
    contrastText: error,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: white,
    secondary: black,
    link: colors.blue[600],
    light: themeLightTextColor,
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  background: {
    default: "#F4F6F8",
    paper: white,
  },
  divider: colors.grey[200],
};
