import React, { lazy, Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";
import MainLayout from "./layouts/MainLayout";
import Welcome from "./views/Welcome";
import AccessMyWallet, { AccessWalletTypes } from "./views/AccessMyWallet";
import NotFound from "./components/NotFound";
import { useDispatch, useSelector } from "react-redux";
import FullPageLoader from "./components/FullPageLoader";
import { lockWalletHandler } from "./actions";
import PrivacyPolicy from "./privacyPolicy/PrivacyPolicy";
import Terms from "./views/Terms&conditions";
const WalletCreateForm = lazy(() => import("./views/WalletCreateForm"));
const Dashboard = lazy(() => import("./views/Dashboard"));

const AppRoutes = () => {
  const dispatch = useDispatch();
  const [state, setstate] = useState(false);
  const { loggedIn } = useSelector((state) => state.session);
  const confirmExit = (e) => {
    dispatch(lockWalletHandler());
  };

  useEffect(() => {
    if (loggedIn) {
      if (state == true) {
        window.onbeforeunload = confirmExit();
      } else {
        setstate(true);
      }
    } else {
      window.onbeforeunload = false;
    }

    return () => {};
  }, [state]);
  const { encryptedData } = useSelector((state) => state.wallet);
  return (
    <Router>
      <Suspense fallback={<FullPageLoader isActive={true} />}>
        <MainLayout>
          <Switch>
            <Route exact={true} path="/">
              {loggedIn ? <Redirect to="/dashboard" /> : <Welcome />}
            </Route>
            <Route path="/create-wallet" component={WalletCreateForm} />
            <Route path="/access-my-wallet" component={AccessMyWallet} />
            <Route
              path="/access-my-wallet-type"
              component={AccessWalletTypes}
            />
            <Route path="/not-found" component={NotFound} />
            <Route path="/terms-and-conditions" component={Terms} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <PrivateRoute path="/dashboard" component={Dashboard} />
            <PrivateRoute path="/settings" component={Dashboard} />
            <Redirect to="/not-found" />
          </Switch>
        </MainLayout>
      </Suspense>
    </Router>
  );
};

export default AppRoutes;
