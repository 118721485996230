export default {
    root: {
        "& .MuiPaginationItem-page.Mui-selected:hover, .MuiPaginationItem-page.Mui-selected.Mui-focusVisible ": {
            backgroundColor: "#40b1be",
            color: "#fff",
        },
        "&.MuiPaginationItem-page.Mui-selected:hover": {
            backgroundColor: "#40b1be",
            color: "#fff",
        },
        "& .MuiPaginationItem-page.Mui-selected ": {
            backgroundColor: "#40b1be",
            color: "#fff",
        },
        "& .MuiPaginationItem-page:hover ": {
            backgroundColor: "#40b1be",
            color: "#fff",
        },
    },
};
