import palette from '../palette';

export default {
  root: {
    zIndex: 1,
    color: 'lightblue',
    width: 34,
    height: 34,
    display: 'flex',
    borderRadius: '50%',
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.1)',
    justifyContent: 'center',
    alignItems: 'center',
    '&-active': {
      backgroundColor: '#40b1be',
      color: '#fff',
    },
    '&-completed': {
      backgroundColor: '#40b1be',
      color: '#fff',
    },
  },
  text: {
    fill: palette.white,
  },
};
