import palette from '../palette';

export default {
  root: {
    padding: '16px 24px',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },
  title: {
    color: palette.white,
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: 'normal',
    lineHeight: 1.56,
  },
  subheader: {
    color: '#666666',
    fontSize: '12px',
    fontWeight: 500,
    letterSpacing: 'normal',
    lineHeight: 1.5,
  },
};
