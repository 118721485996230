import { BASE_URL } from "../utils/contant";
class Api {
    static headers(token) {
        return {
            "Content-Type": "application/json",
        };
    }

    static get(route, token) {
        return this.xhr(route, null, "GET", null);
    }

    static put(route, params, token) {
        return this.xhr(route, params, "PUT", token);
    }

    static post(route, params, token) {
        return this.xhr(route, params, "POST", token);
    }

    static delete(route, token) {
        return this.xhr(route, null, "DELETE", token);
    }

    static fetchUrl(route) {
        return `${BASE_URL}/${route}`;
    }

    static xhr(route, params, verb, token) {
        const url = this.fetchUrl(route);
        const options = Object.assign(
            { method: verb },
            params ? { body: JSON.stringify(params) } : null,
        );
        options.headers = this.headers(token);
        return fetch(url)
            .then((resp) => {
                const json = resp.json();
                if (resp.ok) {
                    return json;
                }
                return json.then((err) => {
                    throw err;
                });
            })
            .then((response) => {
                return response;
            });
    }
}

export default Api;
