import palette from "../palette";

export default {
  root: {
   
  },
  input: {
    
    "&::placeholder": {
      opacity: 1,
      color: "#C0C0C0",
    },
  },
};
