import palette from "../palette";

export default {
  root: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: 2.14,
    letterSpacing: "0.42px",
    border: `solid 0.8px ${palette.primary.main}`,
    borderRadius: "unset",
    textTransform: "capitalize",
    fontFamily: "Graphik-Regular",
    // "& .MuiButton-label": {
    //   fontWeight: 200,
    //   fontStretch: "normal",
    //   fontStyle: "normal",
    // }
  },
  contained: {
    boxShadow: "unset",
    backgroundColor: "transparent",
    color: palette.primary.main,
    border: `solid 0.8px ${palette.primary.main}`,
    "&:hover": {
      boxShadow: "unset",
      backgroundColor: "unset",
    },
  },
};
