import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/styles";
import { connect, useDispatch, useSelector } from "react-redux";
import { Box, colors, Container, Grid, Typography } from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
// import ReactToPrint from "react-to-print";
import { CreateWllaetImg, CreateWllaetImg24 } from "../../assets/images";
import { lightTheme } from "../../vars/theme";
import MnemonicPhraseModal from "../../components/MnemonicPhraseModal";
import { KeyStoreFile } from "../../components/Modal";
import CryptoService from "../../services/cryptoService";
import { login, setMenomicLength, userUnlockWallet } from "../../actions";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
const ioCreata = require("io-creata-js");

// import MnemonicsToPrint from './MnemonicsToPrint';

const useStyles = makeStyles((theme) => ({
    root: (props) => (
        {
            "& .MuiTypography-subtitle2": {
                fontWeight: "bold",
                color: props.activeTheme == lightTheme && theme.palette.text?.secondary
            },
            textAlign: "center",
            padding: 16
        }

    ),
    contentContainer: {
        marginBottom: 15,
        textAlign: "center"
    },
    title: {
        fontFamily: "Graphik-Extralight",
        fontSize: "36px",
        fontWeight: 200,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.23,
        letterSpacing: "0.4px",
        color: theme.palette.primary.main,
        textTransform: "uppercase",
    },
    titleTextColor: {
        color: "#007fc1",
    },
    subtitle: {
        textAlign: "center",
        marginBottom: "18px",
        color: theme.palette.secondary.main,
    },
    content: {
        marginBottom: "20px",
        color: theme.palette.secondary.main,
        lineHeight: 2,
        textAlign: "center"
    },
    contentColor: {
        width: "100%",
        maxWidth: 800,
        marginBottom: 20,
        lineHeight: 1.5,
        textAlign: "center",
        color: theme.palette.secondary.main,
        fontFamily: "Graphik-Light",
    },
    phraseContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        maxWidth: "600px",
        margin: "auto",
        border: `solid 0.8px ${theme.palette.secondary.main}`,
        position: "relative",
        padding: "10px",
    },
    phaseBoxContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        flexWrap: "wrap",
        minHeight: "190px",
    },
    leftLine: {
        position: "absolute",
        width: "22.7px",
        height: "2px",
        margin: "18px -22px",
        backgroundColor: "#25c5f7",
        zIndex: 1,
    },
    maskLeft: (props) => ({
        position: "absolute",
        margin: "12px -22px",
        width: "22.7px",
        height: "16px",
        background: props.activeTheme === lightTheme ? "#eef2f5" : "#160722",
        zIndex: 1,
    }),
    rightLine: {
        position: "absolute",
        width: "22.7px",
        height: "2px",
        margin: "18px -12px",
        right: "0px",
        backgroundColor: "#25c5f7",
        zIndex: 1,
    },
    maskRight: (props) => ({
        position: "absolute",
        margin: "12px -12px",
        width: "22.7px",
        height: "16px",
        right: "0px",
        zIndex: 1,
        background: props.activeTheme === lightTheme ? "#eef2f5" : "#160722",
        // @media (max-width: 768px) {
        //   background: linear-gradient(90deg, #cdbacd 50%, #c49fc4 50%),
        // }
        // @media (max-width: 580px) {
        //   background: linear-gradient(90deg, #9462cd 50%, #4d3b93 50%),
        // }
    }),

    lineContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "0 auto"
    },
    verticalLine: (props) => ({
        height: 60,
        width: 3,
        background: props.activeTheme === lightTheme ? "#b0b9c0" : "#fff",
    }),
    createWalletimg: {
        height: 152,
        objectFit: "contain",
        cursor: "pointer",
    },
    cursor: {
        cursor: "pointer",
    },
}));

const AccessWalletTypes = (props) => {
    const location = useLocation()
    const dispatch = useDispatch()
    const activeTheme = useSelector((state) => state.theme.theme);
    const classes = useStyles({ activeTheme });
    const [isMnemonicModalOpen, setIsMnemonicModalOpen] = useState(false);
    const { t, li8n } = useTranslation();
    const handleMnemonicType = (mnemonicLength) => {
        dispatch(setMenomicLength(mnemonicLength));
        setIsMnemonicModalOpen(true)
    };

    return (
        <Box className={classes.root}>
            <Container>
                <Box className={classes.contentContainer}>
                    <span className={classes.title}>
                        {t("accesswallettype.Restoreyourpersonalwallet")}<br />
                        {t("accesswallettype.accessyourwebwalletAnytime")}
                        <span className={classes.titleTextColor}> {t("accesswallettype.Phrase")} </span>{" "}
                    </span>
                </Box>

                <Typography className={classes.contentColor} variant="body1" gutterBottom>
                    {t("accesswallettype.mainhead")}

                </Typography>

                <Box
                    className={classes.phraseContainer}
                    style={{ marginBottom: "18px" }}
                // ref={(el) => (componentRef = el)}
                >
                    <span className={classes.maskLeft} />
                    <span className={classes.leftLine} />
                    <Box className={classes.phaseBoxContainer}>
                        <Grid container spacing={2}>
                            <Grid item md={5} xs={12} className={classes.cursor} onClick={() => handleMnemonicType(12)}>
                                <img src={CreateWllaetImg} width="150px" className={classes.createWalletimg} />
                                <Typography variant="subtitle2">
                                    {t("accesswallettype.Restorewalletvia12")}<br />
                                    {t("accesswallettype.wordphrase")}
                                </Typography>
                            </Grid>
                            <Grid item md={2} className={classes.lineContainer} >
                                <Box className={classes.verticalLine} />
                            </Grid>
                            <Grid item md={5} xs={12} className={classes.cursor} onClick={() => handleMnemonicType(24)}>
                                <img src={CreateWllaetImg24} width="150px" className={classes.createWalletimg} />
                                <Typography variant="subtitle2">
                                    {t("accesswallettype.Restorewalletvia24")}<br />
                                    {t("accesswallettype.wordphrase")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <span className={classes.maskRight} />
                    <span className={classes.rightLine} />
                </Box>
                <MnemonicPhraseModal
                    setIsMnemonicModalOpen={setIsMnemonicModalOpen}
                    isMnemonicModalOpen={isMnemonicModalOpen}
                />



            </Container>
        </Box>
    );
};

AccessWalletTypes.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.func,
    handleBack: PropTypes.func,
    handleNext: PropTypes.func,
    classes: PropTypes.object,
    getMnemonics: PropTypes.func,
};



export default withRouter(AccessWalletTypes);
