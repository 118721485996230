import { CHANGE_THEME } from "../actions/themeActions";
import { lightTheme } from "../vars/theme";

const initialState = {
  theme: lightTheme,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CHANGE_THEME:
      localStorage.setItem("theme", payload);
      return { ...state, theme: payload };

    default:
      return state;
  }
};
