import * as actionTypes from "../actions";

const initialState = {
  notifyCheck: false,
};

const autoLockReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.NOTIFICATION: {
      return {
        ...state,
        notifyCheck: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default autoLockReducer;
