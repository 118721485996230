import palette from "../palette";

export default {
  root: {
    border: `0.8px solid ${palette.primary.main}`,
    borderRadius: "unset",
    background: "#ffffff26",
    height: "54px",
  },
  notchedOutline: {
    borderColor: "rgba(0,0,0,0.15)",
  },
};
