/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @next/next/no-img-element */
import React from "react";
import TermsAnsCondition from "./TermsAnsCondition";

const Terms = () => {
  return (
    <div>
      <TermsAnsCondition />
    </div>
  );
};

export default Terms;
