export const BASE_URL = process.env.REACT_APP_CREATACHAIN_SOCKET_URL;
export const encryptionConst = {
  DEFAULT_SALT: process.env.REACT_APP_ENCRYPTION_CONSTANT,
};
export const schema = (t) => {
  return {
    password: {
      length: {
        maximum: 20,
        minimum: 8,
        //    tooShort:   `${t('validationmessage.short1')}${'%{count}'}${t('validationmessage.short2')}` ,
        //   tooShort:'needs to have %{count} words or more' ,
        //   tooLong: `${t('validationmessage.long1')}${'%{count}'}${t('validationmessage.long2')}` ,
      },
      format: {
        pattern:
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#~`/\|=)(?!@$%^&*-]).{8,}$/,
        // message: 'xxx x x x',
      },
    },

    confirmPassword: {
      length: {
        maximum: 20,
        minimum: 8,
        //     tooShort:   `${t('validationmessage.short1')}${'%{count}'}${t('validationmessage.short2')}` ,
        //    tooLong: `${t('validationmessage.long1')}${'%{count}'}${t('validationmessage.long2')}` ,
      },
      equality: {
        attribute: "password",
        //  message:  <>{t('validationmessage.confirmpassword')}</>,
        comparator: function (v1, v2) {
          return JSON.stringify(v1) === JSON.stringify(v2);
        },
      },
    },
  };
};

export const changePasswordSchema = (t) => {
  return {
    newPassword: {
      length: {
        maximum: 20,
        minimum: 8,
        //    tooShort:   `${t('validationmessage.short1')}${'%{count}'}${t('validationmessage.short2')}` ,
        //   tooShort:'needs to have %{count} words or more' ,
        //   tooLong: `${t('validationmessage.long1')}${'%{count}'}${t('validationmessage.long2')}` ,
      },
      format: {
        pattern:
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#~`/\|=)(?!@$%^&*-]).{8,}$/,
        // message: 'xxx x x x',
      },
    },
    confirmPassword: {
      length: {
        maximum: 20,
        minimum: 8,
        //     tooShort:   `${t('validationmessage.short1')}${'%{count}'}${t('validationmessage.short2')}` ,
        //    tooLong: `${t('validationmessage.long1')}${'%{count}'}${t('validationmessage.long2')}` ,
      },
      equality: {
        attribute: "newPassword",
        //  message:  <>{t('validationmessage.confirmpassword')}</>,
        comparator: function (v1, v2) {
          return JSON.stringify(v1) === JSON.stringify(v2);
        },
      },
    },
  };
};

export const sendTransactionSchema = {
  reciverKey: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 50,
    },
  },
  senderKey: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 50,
    },
  },
  SMH: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 20,
    },
  },
  transactionType: {
    presence: { allowEmpty: false, message: "is required" },
  },
};

const denomToSymbol = (denom) => {
  const symbol = denom.replace("u", "").toUpperCase() || "";
  return symbol;
};

// const NumberFormat = (value, numberOfDecimals = 3) => {
//   const numberValue = Number(value);
//   return numberValue.toLocaleString(undefined, {
//     minimumFractionDigits: numberOfDecimals,
//     maximumFractionDigits: numberOfDecimals,
//   });
// };
export { denomToSymbol };
