export const LOCK_ON = "LOCK_ON";
export const LOCK_OFF = "LOCK_OFF";
export const SET_TIME = "SET_TIME";

export const lockon = () => (dispatch) =>
  dispatch({
    type: LOCK_ON,
  });

export const lockoff = () => (dispatch) =>
  dispatch({
    type: LOCK_OFF,
  });

export const settime = (data) => (dispatch) =>
  dispatch({
    type: SET_TIME,
    payload: data,
  });
