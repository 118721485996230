import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    
    "& .MuiTableBody-root": {
      borderBottom: "0.5px solid #b4d4e6",
    },
    "& .MuiTableCell-body": {
      // color: "#090909",
      color:theme.palette.primary.darklight,
      fontFamily: "Graphik-Light",
      fontSize: "16px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
      lineHeight: 1.5,
      textAlign: "left",
      fontWeight: 300,
    },
    "& .MuiTableCell-head": {
       color: "#090909",
      // color:theme.palette.primary.main,
      fontSize: "16px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
       // padding:"6px",
      },
      fontWeight: " normal",
      textAlign: "left",
      fontFamily: "Graphik-Regular",
      width: "160px",
    },

    "& .MuiTableCell-root":
    {
      // [theme.breakpoints.down("sm")]: {
        
      //   padding:"10px",
      // },
      // [theme.breakpoints.down("xs")]: {
        
      //   padding:"6px",
      // },
    }
  },
  cellStyle: {
    verticalAlign: "text-top",
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#bad7e73d",
    },
    "&:nth-of-type(even)": {
      borderTop: "0.5px solid #b4d4e6",
    },
  },
}))(TableRow);
const TableInfo = () => {
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <TableContainer
      style={{ borderRadius: "15px", border: "0.2px solid #b4d4e6"}}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t("table.thead1")}</TableCell>
            <TableCell>
              {t("table.thead2")}
            </TableCell>
            <TableCell>{t("table.thead3")}</TableCell>
            <TableCell>{t("table.thead4")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow className={classes.cellStyle}>
            <TableCell>
            {t("table.tdata1")}
            </TableCell>
            <TableCell>
            {t("table.tdata2")}
            </TableCell>
            <TableCell>
            {t("table.tdata3")}
            </TableCell>
            <TableCell>
            {t("table.tdata4")}
            </TableCell>
          </StyledTableRow>
          {/* second row */}
          <StyledTableRow className={classes.cellStyle}>
            <TableCell component="th" scope="row">
            {t("table.tdata5")}
            </TableCell>
            <TableCell>
            {t("table.tdata6")}
            </TableCell>
            <TableCell>
            {t("table.tdata7")}
            </TableCell>
            <TableCell>
            {t("table.tdata8")}
            </TableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
  
};

export default TableInfo;
