import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import Navbar from "../NavBar";
import Footer from "../Footer";
// import MainFooter from "../MainFooter";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import SuccessBar from "../../components/SuccessBar/SuccessBar";
import { useDispatch, useSelector } from "react-redux";
import ErrorBar from "../../components/ErrorBar";
import { backrground, backrgroundLight } from "../../assets/images";
import { useHistory,useLocation } from "react-router";
import { lightTheme } from "../../vars/theme";
// const activeTheme = localStorage.getItem("theme");
// const backrgroundImage =
//   activeTheme === "lightTheme" ? backrgroundLight : backrground;

const useStyles = makeStyles((theme, themeName,path) => ({
  foo: (props) => ({
    backgroundColor: props.backgroundColor,
  }),

  root: (props) => ({
    backgroundImage: `url(${props.themeName === lightTheme ? backrgroundLight : backrground
      })`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    //flexGrow: 1,
    width:"100%",
    backgroundAttachment: props.path === "/privacy-policy"||props.path === "/terms-and-conditions" ? "fixed" : "Scroll",
  }),
  content: {
    display: "flex",
    flex: "1 1 0%",
    justifyContent: "center",
    alignItems: "center",
     width: "100%",
    // backgroundColor: theme.palette.primary.background,
    // margin: "45px auto",
  },
}));

const MainLayout = (props) => {
  const location = useLocation();
  const path = location?.pathname;
  const theme = useSelector((state) => state.theme.theme);
  const classes = useStyles({ themeName: theme,path});
  const dispatch = useDispatch();
  const history = useHistory();


  const isSuccessOpen = useSelector((state) => state.wallet.isSuccessOpen);
  const successText = useSelector((state) => state.wallet.successText);
  const isErrorOpen = useSelector((state) => state.wallet.isErrorOpen);
  const errorText = useSelector((state) => state.wallet.errorText);
  const { encryptedData } = useSelector((state) => state.wallet);
  const isFileSuccessOpen = useSelector(
    (state) => state.wallet.isFileSuccessOpen
  );
  const fileSuccessText = useSelector((state) => state.wallet.fileSuccessText);
  const handleCloseSnackbar = () => {
    dispatch({ type: "CLOSE_SEND_TXS_BAR", payload: false });
  };
  // useEffect(() => {
  //     if (!!encryptedData) {
  //         history.push("/unlock-wallet");
  //     } else {
  //         history.push("/");
  //     }
  // }, [encryptedData, history]);
  return (
    <Box className={classes.root}>
      <Navbar />
      <SuccessBar
        isOpen={isSuccessOpen || isFileSuccessOpen}
        text={successText || fileSuccessText}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <ErrorBar
        isOpen={isErrorOpen}
        text={errorText}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Box className={classes.content}>{props.children}</Box>
      <Footer />
    </Box>
  );
};

MainLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default MainLayout;
