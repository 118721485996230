import API from "../services/api";
import { logout } from "../actions";
import io from "socket.io-client";
import axios from "axios";
const CryptoJS = require("crypto-js");
const ioCreata = require("io-creata-js");

export let socket;
export const socketClient = (address) => async (dispatch, getState) => {
    if (socket) {
        dispatch(socketTerminateClient());
    }
    socket = io(`${process.env.REACT_APP_CREATACHAIN_SOCKET_URL}?address=${address}`, {
        transports: ["websocket"],
        autoConnect: false,
    });

    await socket.connect();
    return socket;
};
export const SET_WALLET_META = "SET_WALLET_META";
export const SET_ACCOUNTS = "SET_ACCOUNTS";
export const SET_CURRENT_ACCOUNT_INDEX = "SET_CURRENT_ACCOUNT_INDEX";
export const SET_MNEMONIC = "SET_MNEMONIC";
export const SET_TRANSACTIONS = "SET_TRANSACTIONS";
export const SET_CONTACTS = "SET_CONTACTS";
export const GET_ACCOUNT_DETAILS = "GET_ACCOUNT_DETAILS";
export const GET_ACCOUNT_DETAILS_LOADING = "GET_ACCOUNT_DETAILS_LOADING";
export const CLEAR_STATES = "CLEAR_STATES";
export const GET_All_TRANSACTIONS = "GET_All_TRANSACTIONS";
export const GET_All_TRANSACTIONS_LOADING = "GET_LATEST_TRANSACTIONS_LOADING";
export const SET_ADDRESS = "SET_ADDRESS";
export const SET_SELECTED_COIN = "SET_SELECTED_COIN";
export const SET_SELECTED_COIN_LOADING = "SET_SELECTED_COIN_LOADING";

export const GET_PSIX_MARKET_PRICE = "GET_PSIX_MARKET_PRICE";
export const CREATE_TX_LOADING = "CREATE_TX_LOADING";

export const SET_SELECTED_ROW = "SET_SELECTED_ROW";

export const SET_GRAPH_DATA = "SET_GRAPH_DATA";
export const SET_GRAPH_DATA_LOADING = "SET_GRAPH_DATA_LOADING";

export const GET_MARKET_PRICE = "GET_MARKET_PRICE";
export const SEND_TXS_LOADING = "SEND_TXS_LOADING";
export const CLOSE_SEND_TXS_BAR = "CLOSE_SEND_TXS_BAR";

export const SUCCESS_BAR_CONTENT = "SUCCESS_BAR_CONTENT";
export const ERROR_BAR_CONTENT = "ERROR_BAR_CONTENT";

export const SENT_TXS_HASH = "SENT_TXS_HASH";

export const BACKUP_FILE_SUCCESS_BAR_CONTENT = "BACKUP_FILE_SUCCESS_BAR_CONTENT";
export const SET_SEARCH_BAR_KEYWORD = "SET_SEARCH_BAR_KEYWORD";
export const SET_ENCRYPTED_DATA = "SET_ENCRYPTED_DATA";
export const SET_MNEMONIC_LENGTH = "SET_MNEMONIC_LENGTH";
export const SET_PHRASE = "SET_PHRASE";
export const SET_TXS_RES = "SET_TXS_RES";
export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const SET_PERMITION = "SET_PERMITION";
export const ACTIVE_ACCOUNTS = "ACTIVE_ACCOUNTS";
export const ACTIVE_INDEX = "ACTIVE_INDEX";
export const GET_PRICE_DETAIL = "GET_PRICE_DETAIL";
export const SET_CALCULATE = "SET_CALCULATE";
export const setSearchBarKeyword = (value) => ({
    type: SET_SEARCH_BAR_KEYWORD,
    payload: value,
});

export const setWalletMeta = (meta) => ({
    type: SET_WALLET_META,
    payload: meta,
});

export const setAccounts = (accounts) => ({
    type: SET_ACCOUNTS,
    payload: accounts,
});

export const setMnemonic = (mnemonic) => ({
    type: SET_MNEMONIC,
    payload: mnemonic,
});

export const setTransactions = (transactions) => ({
    type: SET_TRANSACTIONS,
    payload: transactions,
});

export const getAccountDetailsLoading = (loading) => ({
    type: GET_ACCOUNT_DETAILS_LOADING,
    payload: loading,
});

export const getGraphDetailsLoading = () => ({
    type: SET_GRAPH_DATA_LOADING,
});

export const getAllTransactionsLoading = () => ({
    type: GET_All_TRANSACTIONS_LOADING,
});

export const sendTransactionLoading = (value) => ({
    type: SEND_TXS_LOADING,
    payload: value,
});

export const successBarContent = (isOpen, message) => ({
    type: SUCCESS_BAR_CONTENT,
    payload: {
        isOpen: isOpen,
        message: message,
    },
});

export const errorBarContent = (isOpen, message) => ({
    type: ERROR_BAR_CONTENT,
    payload: {
        isOpen: isOpen,
        message: message,
    },
});

export const sentTxsHash = (value, denom, amount, toAddress, hash) => ({
    type: SENT_TXS_HASH,
    payload: {
        isTxsHash: value,
        denom: denom,
        amount: amount,
        toAddress: toAddress,
        txsHash: hash,
    },
});

export const setEncryptedData = (data) => (dispatch) =>
    dispatch({
        type: SET_ENCRYPTED_DATA,
        payload: data,
    });
export const setMenomicLength = (mnemonicLength) => (dispatch) =>
    dispatch({
        type: SET_MNEMONIC_LENGTH,
        payload: mnemonicLength,
    });
export const setPharse = (mnemonic) => (dispatch) =>
    dispatch({
        type: SET_PHRASE,
        payload: mnemonic,
    });

export const userUnlockWallet = (meta, accounts, mnemonic) => async (dispatch) => {
    dispatch(setWalletMeta(meta));
    dispatch(setAccounts(accounts));
    dispatch(setMnemonic(mnemonic));
};

export const setPermition = (permition) => (dispatch) =>
    dispatch({
        type: SET_PERMITION,
        payload: permition,
    });

// export const getAccountDetails = (address) => (dispatch) => {
//   dispatch(getAccountDetailsLoading(true));
//   API.get(`api/web/bank/balance/${address}`)
//     .then((res) => {
//       dispatch(getAccountDetailsLoading(false));
//       dispatch({
//         type: GET_ACCOUNT_DETAILS,
//         payload: res.data.coins,
//       });
//     })
//     .catch((err) => {
//       dispatch(getAccountDetailsLoading(false));
//       dispatch({
//         type: GET_ACCOUNT_DETAILS,
//         payload: [],
//       });
//     });
// };

// export const getMarketPrice = () => (dispatch) => {
//   fetch(process.env.REACT_APP_CTA_TOKEN_URL, {
//     method: "GET",
//   })
//     .then((response) => response.json())
//     .then((res) => {
//       dispatch({
//         type: GET_MARKET_PRICE,
//         payload: res.data,
//       });
//     })
//     .catch((err) => {
//       dispatch({
//         type: GET_MARKET_PRICE,
//         payload: {},
//       });
//     });
// };

// export const getAllTransactions = (address) => (dispatch) => {
//   dispatch(getAllTransactionsLoading());
//   API.get(`api/web/transaction/txs?address=${address}`)
//     .then((res) => {
//       dispatch({
//         type: GET_All_TRANSACTIONS,
//         payload: res.data,
//       });
//     })
//     .catch((err) => {
//       dispatch({
//         type: GET_All_TRANSACTIONS,
//         payload: null,
//       });
//     });
// };

export const setTxsResponse = (data) => (dispatch) => {
    dispatch({
        type: SET_TXS_RES,
        payload: data,
    });
};

export const sendTransaction =
    (toAddress, sentAmount, fee, memo, denom, currentAmount, handleClose, privateKey) =>
    async (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch(sendTransactionLoading(true));
            const { address, permition } = getState().wallet;

            fetch(process.env.REACT_APP_RPC_SERVER_URL + `/users/getAccounts/?address=${address}`, {
                method: "GET",
                headers: {
                    "x-creata-secret-web-wallet": "CRA-WEB-WALLET-TX",
                },
            })
                .then((response) => response.json())
                .then(async (res) => {
                    const data = JSON.parse(res);
                    const chainId = process.env.REACT_APP_CHAIN_ID;
                    let x = ioCreata.network(
                        `${process.env.REACT_APP_CREATACHAIN_IOCREATA_URL}`,
                        chainId,
                    );
                    let stdSignMsg = x.newStdMsg({
                        msgs: [
                            {
                                type: "creata-sdk/MsgSend",
                                value: {
                                    amount: [
                                        {
                                            amount: String(sentAmount * 1000000), // 6 decimal places (1000000 ucta = 1 CTA)
                                            denom: denom, // coin denomination is ucta
                                        },
                                    ],
                                    from_address: address,
                                    to_address: toAddress,
                                },
                            },
                        ],
                        chain_id: chainId,
                        fee: {
                            amount: [{ amount: String(fee), denom: denom }],
                            gas: String(200000),
                        },

                        memo: memo == undefined ? "" : memo,
                        account_number: data.result.value.account_number,
                        sequence:
                            data.result.value.sequence === undefined
                                ? "0"
                                : data.result.value.sequence,
                    });

                    const signedTx = await x.sign(stdSignMsg, privateKey);

                    var myHeaders = new Headers();
                    myHeaders.append("x-creata-secret-web-wallet", "CRA-WEB-WALLET-TX");
                    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                    var urlencoded = new URLSearchParams();
                    urlencoded.append("signedTx", JSON.stringify(signedTx));

                    var requestOptions = {
                        method: "POST",
                        headers: myHeaders,
                        body: urlencoded,
                        redirect: "follow",
                    };

                    fetch(process.env.REACT_APP_RPC_SERVER_URL + "/users/webTx", requestOptions)
                        .then((response) => response.text())
                        .then(async (result) => {
                            const res = JSON.parse(result);
                            const response = JSON.parse(res);

                            dispatch(sendTransactionLoading(false));
                            // dispatch(
                            //   successBarContent(true, "Heading.TransactionSentSuccessfully")
                            // );

                            dispatch({
                                type: SET_SELECTED_COIN,
                                payload: {
                                    amount: currentAmount * 1000000,
                                    denom: denom,
                                },
                            });
                            await handleClose(false);
                            const ress = dispatch(
                                sentTxsHash(true, denom, sentAmount, toAddress, response.txhash),
                            );
                            dispatch(setTxsResponse(ress));
                            resolve(response.txhash);
                        })
                        .catch((err) => {
                            dispatch(sendTransactionLoading(false));
                            dispatch(errorBarContent(true, "Heading.TransactionSendingFailed"));
                        });
                })
                .catch((err) => console.log(err));
        });
    };

export const calculateTxsRisk = (toAddress, sentAmount) => async (dispatch, getState) => {
    dispatch(sendTransactionLoading(true));

    const { address, permition } = getState().wallet;
    const cipherText = CryptoJS.AES.encrypt(
        process.env.REACT_APP_MESSAGE,
        process.env.REACT_APP_ENCRYPT_DECRYPT_SECRET,
    ).toString();

    return new Promise((resolve, reject) => {
        axios
            .post(`${process.env.REACT_APP_RISK_API}/kyt/riskScore`, {
                from: address,
                to: toAddress,
                amount: Number(sentAmount),
                token: cipherText,
            })
            .then((res) => {
                dispatch(sendTransactionLoading(false));
                resolve(res.data); // Resolve the promise with the response
            })
            .catch((err) => {
                dispatch(sendTransactionLoading(false));
                reject(err); // Reject the promise with the error
            });
    });
};
export const sendTxHash = (id, txHash) => async (dispatch, getState) => {
    // dispatch(sendTransactionLoading(true));

    const cipherText = CryptoJS.AES.encrypt(
        process.env.REACT_APP_MESSAGE,
        process.env.REACT_APP_ENCRYPT_DECRYPT_SECRET,
    ).toString();
    axios
        .patch(`${process.env.REACT_APP_RISK_API}/kyt/riskScore/${id}`, {
            hash: txHash,
            token: cipherText,
        })
        .then((res) => {
            dispatch(sendTransactionLoading(false));
            return res.status;
        })
        .catch((err) => {
            dispatch(sendTransactionLoading(false));
        });
};

export const getGraphDetails = (address) => (dispatch) => {
    dispatch(getGraphDetailsLoading());
    API.get(`api/graph/graphData`)
        .then((res) => {
            dispatch({
                type: SET_GRAPH_DATA,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: SET_GRAPH_DATA,
                payload: null,
            });
        });
};

export const lockWalletHandler = () => (dispatch) => {
    dispatch(socketDisconnect());
    dispatch(logout());
    dispatch({ type: "CLEAR_STATES" });
};

var validateKeyPress = false;

export const refreshHandler = () => (dispatch) => {
    window.document.addEventListener("keydown", function (event) {
        if (
            event.keyCode === 16 ||
            event.keyCode === 17 ||
            event.keyCode === 82 ||
            event.keyCode === 116
        ) {
            event.preventDefault();
            return (validateKeyPress = true);
        }
    });

    if (window.close) {
        if (validateKeyPress) {
            return null;
        } else {
            const check = window.confirm("You will be logged out. Do you want to refresh?");
            if ((window.onbeforeunload = check)) {
                return dispatch(lockWalletHandler());
            }
        }
    }
};

export const socketConnect = () => async (dispatch) => {
    socket.connect();
};

export const socketDisconnect = () => async (dispatch, getState) => {
    //   const address = getState().wallet.address;
    //   socket.emit("terminate", address);
    socket.disconnect();
};

export const socketRegisterClient = (address) => async (dispatch) => {
    dispatch(getAccountDetailsLoading(true));
    dispatch(getAllTransactionsLoading());
    socket.emit("addMe", address);
    if (socket.disconnected) {
        dispatch(getAccountDetailsLoading(false));
        dispatch({
            type: GET_ACCOUNT_DETAILS,
            payload: [],
        });
        dispatch({
            type: GET_All_TRANSACTIONS,
            payload: null,
        });
    }
};

export const listenTransactions = (address) => (dispatch) => {
    socket.on(address, (data) => {
        dispatch({
            type: GET_All_TRANSACTIONS,
            payload: data,
        });
    });
};

export const listenBalance = () => (dispatch, getState) => {
    const address = getState().wallet.address;
    dispatch(getAccountDetailsLoading(true));
    socket.on(address + "walletBalance", (data) => {
        dispatch(getAccountDetailsLoading(false));
        dispatch({
            type: GET_ACCOUNT_DETAILS,
            payload: data,
        });
    });
};

export const listenPrice = () => (dispatch) => {
    socket.on("price", (data) => {
        dispatch({
            type: GET_PRICE_DETAIL,
            payload: data,
        });
    });
};

export const activeAccount = (activeAccounts) => ({
    type: ACTIVE_ACCOUNTS,
    payload: activeAccounts,
});
export const activeIndex = (activeItemIndex) => ({
    type: ACTIVE_INDEX,
    payload: activeItemIndex,
});
export const socketTerminateClient = () => async (dispatch, getState) => {
    const address = getState().wallet.address;
    socket.emit("terminate", address);
};
// export const registerNotification = (data) => async (dispatch) => {
//   socket.emit("notification", data);
//   dispatch({
//     type: GET_NOTIFICATION,
//     payload: null,
//   });
// };

export const listenNotification = () => async (dispatch, getState) => {
    const address = getState().wallet.address;
    socket.on(address + "Notification", (data) => {
        dispatch({
            type: GET_NOTIFICATION,
            payload: data,
        });
    });
};
