import React from "react";
// import { Container, Button } from "reactstrap";
// import styled from "styled-components";

import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Box, Typography, Button, TextField } from "@material-ui/core";
import { logo, fourOFour } from "../../assets/images";
import { useTranslation} from "react-i18next";
const useStyles = makeStyles((theme) => ({
    backWrapper: {
        display: "flex",
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
    },

    wrapper: {
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
    },
    fourOFour: {
        [theme.breakpoints.down("xs")]: {
            height: "140px",
        },
    },

    content: {
        fontFamily: "Graphik-ExtraLight",
        fontSize: 35,
        fontWeight: 200,
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: 1.72,
        lineHeight: 2,
        textAlign: "center",
        color: "#fff",
        marginBottom: 18,

        [theme.breakpoints.down("xs")]: {
            fontSize: 27,
        },
    },

    logo: {
        width: 49.5,
        height: 57.4,
        objectFit: "contain",
        marginRight: 14,
    },

    logoBox: {
        display: "flex",
        width: "100%",
        paddingTop: 20,
    },
    logoText: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    heading1: {
        fontFamily: "Graphik-ExtraLight",
        fontSize: 22,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.45,
        letterSpacing: 2.35,
        textAlign: "left",
        color: " #007fc1",
    },

    // buttonContainer: {
    //     // position: "relative",
    //     width: 168,
    //     margin: "auto",
    // },

    customButton: {
        fontfamily: "Graphik-Light",
        fontsize: 15,
        lineheight: 1.44,
        color: "#fff",
        cursor: "pointer",
        border: "1 solid #fff",
        width: 163,
        position: "relative",
        marginBottom: 40,
    },

    leftLine: {
        position: "absolute",
        width: 22.7,
        height: 2,
        margin: "17px -9px",
        padding: "1px 0px",
        left: -4,
        backgroundColor: "#25c5f7",
        zIndex: 1,
    },

    rightLine: {
        position: "absolute",
        width: 22.7,
        height: 2,
        margin: "20px -8px",
        right: -4,
        padding: "1px  0px",
        backgroundColor: "#25c5f7",
    },

    maskLeft: {
        position: "absolute",
        margin: " 11px -8px",
        width: 22.7,
        height: 16,
        left: 0,
        backgroundColor: "#160723",
    },

    maskRight: {
        position: "absolute",
        margin: " -24px -8px",
        width: 22.7,
        height: 16,
        right: 0,
        backgroundColor: "#160723",
        // @media (max-width: 1600px) {
        //     background: linear-gradient(90deg, #0f0415 50%, #0f0415 50%),
        // }
    },
}));

const NotFound = () => {
    const classes = useStyles();
    const history = useHistory();
    const {t,li8n} =  useTranslation();
    return (
        <Box className={classes.backWrapper}>
            <Container>
                <Box className={classes.wrapper}>
                    <img src={fourOFour} alt="fourOFour" className={classes.fourOFour} />
                    <Typography className={classes.content}>
                        {t("notfound.Looklikeyouarelostinspace")}
                    </Typography>
                    <Button className={classes.customButton} onClick={() => history.push("/")}>
                      {t("notfound.Gotohome")}
                        <span className={classes.maskLeft} />
                        <span className={classes.leftLine} />
                        <span className={classes.maskRight} />
                        <span className={classes.rightLine} />
                    </Button>
                </Box>
            </Container>
        </Box>
    );
};

export default NotFound;
