import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, Typography, useTheme } from "@material-ui/core";
import { useHistory } from "react-router";
import Button from "../../components/Button";
import CountryDrop from "../../countryData/CountryDrop";
import { useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "900px",
    width: "100%",
    margin: "auto",

    // [theme.breakpoints.down("xs")]: {
    //   width: "95%",
    // },
  },
  contentContainer: {
    maxWidth: "600px",
    margin: "auto",
    textAlign: "center",
    marginBottom: 32,
    // [theme.breakpoints.down("xs")]: {
    //   margin: "45px 25px",
    // },
  },
  title: {
    fontFamily: "Graphik-Extralight",
    fontSize: "40px",
    fontWeight: 200,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.23,
    letterSpacing: "0.4px",
    color: theme.palette.primary.contrastText,
    textTransform: "uppercase",
    [theme.breakpoints.down("xs")]: {
      fontSize: 25,
    },
  },
  content1: {
    textAlign: "center",
    marginBottom: "30px",
    color: theme.palette.secondary.main,
    [theme.breakpoints.down("xs")]: {
      textAlign: "justify",
      fontSize: 12,
      padding: "0px 10px",
    },
  },
  btn: {
    marginBottom: 10,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 10,
    },
  },
  content2: {
    color: "#29fffb",
    textAlign: "center",
    marginBottom: "20px",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "360px",
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  titleTextColor: {
    color: "#007fc1",
  },
}));

const SplashScreen = () => {
  const classes = useStyles();
  const history = useHistory();
  const {t,li8n} =  useTranslation();
  const theme = useTheme();
  return (
    <Box className={classes.root}>
      <Box className={classes.contentContainer}>
        <span className={classes.title}>
        {t("welcome.Hellothere!Welcometo")}  &nbsp;
          <span className={classes.titleTextColor}>{t("welcome.create")}</span>
        </span>
      </Box>
      <Typography className={classes.content1} variant="subtitle2" gutterBottom>
        {t("welcome.mainhead")}
      </Typography>
      {/* <Typography
          className={classes.content2}
          variant="subtitle2"
          gutterBottom
        >
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
        </Typography> */}
      <div className={classes.btnContainer}>
        <Button
          title={t("welcome.Createawallet")}
          maxWidth={"160px"}
          onClick={() => history.push("/create-wallet")}
        />
        <Button
          title= {t("welcome.AccessWallet")}
          maxWidth={"160px"}
          onClick={() => history.push("/access-my-wallet")}
        /> 
      
      </div>
    </Box>
  );
};

export default SplashScreen;
