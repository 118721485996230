/* eslint-disable no-unused-vars */
import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Container, Box, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions";
import { socketDisconnect } from "../../actions/walletActions";

import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import {
    lockIcon,
    logo,
    logoutLightIcon,
    playicon,
    playiconLight,
    settingIcon,
    settingsLight,
    themeBlueIcon,
    themeWhiteIcon,
} from "../../assets/images";
import { handleChangeTheme } from "../../actions/themeActions";
import { darkTheme, lightTheme } from "../../vars/theme";
import CountryDrop from "../../countryData/CountryDrop";

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: "unset",
        background: "transparent",
        position: "relative",
        paddingRight: "2px !important",
    },
    navbarContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        minHeight: "100px",
        [theme.breakpoints.down("xs")]: {
            minHeight: "75px",
        },
    },
    companyLogo: {
        cursor: "pointer",
        [theme.breakpoints.down("xs")]: {
            width: "120px",
        },
    },
    logoContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        "@media (max-width: 380px)": {
            flexWrap: "wrap",
            justifyContent: "flex-end",
        },
    },
    logoMarginLeft: {
        marginRight: "8px",
        cursor: "pointer",
        [theme.breakpoints.down("xs")]: {
            height: "24px",
        },
    },
    logoNavItem: {
        marginRight: "8px",
        cursor: "pointer",
        [theme.breakpoints.down("xs")]: {
            height: "16px",
        },
    },
    row: {
        display: "flex",
        alignItems: "center",
    },
    mr: {
        marginRight: "8px",
        fontSize: "16px",
    },
    inLine: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginLeft: "18px",
    },
    inLine2: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginLeft: "18px",
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
    },
    iconSettings: {
        [theme.breakpoints.down("xs")]: {
            width: "22px",
            height: "22px",
        },
    },
    linkStyle: {
        textDecoration: "none",
    },
    title: {
        [theme.breakpoints.down("xs")]: {
            fontSize: 11,
            whiteSpace: "nowrap",
        },
        color: theme.palette.secondary.main,
    },
}));

const TopBar = (props) => {
    const { className } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const isLoggedIn = useSelector((state) => state.session.loggedIn);
    const theme = useSelector((state) => state.theme.theme);
    const history = useHistory();
    const { t, li8n } = useTranslation();
    const themeIcon = theme === "lightTheme" ? themeBlueIcon : themeWhiteIcon;
    const logoutIcon = theme === "lightTheme" ? logoutLightIcon : lockIcon;
    const settingsIcon = theme === "lightTheme" ? settingsLight : settingIcon;
    const triangleIcon = theme === "lightTheme" ? playiconLight : playicon;

    const lockWalletHandler = () => {
        dispatch(socketDisconnect());
        dispatch(logout());
        dispatch({ type: "CLEAR_STATES" });
    };

    const createWallet = () => {
        history.push("/create-wallet");
    };

    const accessWallet = () => {
        history.push("/access-my-wallet");
    };

    const toggleTheme = () => {
        theme === darkTheme
            ? dispatch(handleChangeTheme(lightTheme))
            : dispatch(handleChangeTheme(darkTheme));
    };

    return (
        <AppBar className={clsx(classes.root, className)}>
            <Container>
                <Box className={classes.navbarContainer}>
                    <RouterLink to="/dashboard">
                        <img alt="Logo" src={logo} className={classes.companyLogo} />
                    </RouterLink>
                    {isLoggedIn ? (
                        <Box className={classes.logoContainer}>
                            <Box
                                style={{
                                    borderRadius: "4px",
                                    border:
                                        theme === darkTheme ? "1px solid #fff" : "1px solid #000",
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "0px 8px",
                                    marginTop: 2,
                                    marginRight: "-10px",
                                    height: "30px",
                                }}>
                                <Typography
                                    variant="subtitle1"
                                    className={classes.title}
                                    style={{ fontWeight: 600 }}>
                                    Testnet
                                </Typography>
                            </Box>
                            <Box className={classes.inLine}>
                                <CountryDrop />
                            </Box>
                            <RouterLink to="/settings">
                                <Box className={classes.inLine}>
                                    <img
                                        src={settingsIcon}
                                        alt="setting"
                                        className={classes.iconSettings}
                                    />
                                </Box>
                            </RouterLink>
                            {/* )} */}
                            <Box onClick={lockWalletHandler} className={classes.inLine}>
                                <img src={logoutIcon} alt="icon" className={classes.iconSettings} />
                            </Box>
                            <Box className={classes.inLine} onClick={toggleTheme}>
                                <img src={themeIcon} alt="icon" className={classes.iconSettings} />
                            </Box>
                        </Box>
                    ) : (
                        <Box className={classes.logoContainer}>
                            <Box
                                style={{
                                    borderRadius: "4px",
                                    border:
                                        theme === darkTheme ? "1px solid #fff" : "1px solid #000",
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "0px 8px",
                                    marginTop: 2,
                                    marginRight: "-10px",
                                    height: "30px",
                                }}>
                                <Typography
                                    variant="subtitle1"
                                    className={classes.title}
                                    style={{ fontWeight: 600 }}>
                                    Testnet
                                </Typography>
                            </Box>
                            <Box className={classes.inLine}>
                                <CountryDrop />
                            </Box>

                            <Box className={classes.inLine2} onClick={createWallet}>
                                <img
                                    src={triangleIcon}
                                    alt="icon"
                                    className={classes.logoNavItem}
                                />
                                <Typography variant="subtitle1" className={classes.title}>
                                    {t("navbar.CreateWallet")}
                                </Typography>
                            </Box>

                            <Box className={classes.inLine2} onClick={accessWallet}>
                                <img
                                    src={triangleIcon}
                                    alt="icon"
                                    className={classes.logoNavItem}
                                />
                                <Typography variant="subtitle1" className={classes.title}>
                                    {t("navbar.AccessWallet")}
                                </Typography>
                            </Box>

                            <Box className={classes.inLine} onClick={toggleTheme}>
                                <img src={themeIcon} alt="icon" className={classes.iconSettings} />
                            </Box>
                        </Box>
                    )}
                </Box>
            </Container>
        </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string,
    onOpenNavBarMobile: PropTypes.func,
};

export default TopBar;
