/* eslint-disable @next/next/no-img-element */
import { Container, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Bullet from "../../assets/images/privacypolicy/bullet.png";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "transparent",
    position: "relative",
  },
  bold: {
    fontWeight: "bold",
    fontStyle: "italic",
    marginRight: "8px",
  },
  imageContainer: {
    width: "44px",
    height: "55px",
    display: "inline-flex",
  },
  h1: (props) => ({
    color: props.activeTheme == "lightTheme" ? "#090909" : "#ffff",
    fontSize: "22px",
    lineHeight: 2,
    [theme.breakpoints.down("xs")]: {
      lineHeight: 1.5,
      fontSize: "18px",
    },

    fontWeight: " normal",
    textAlign: "left",
    fontFamily: "Graphik-Regular",
  }),

  detail: (props) => ({
    color: props.activeTheme == "lightTheme" ? "#090909" : "#ffff",
    fontFamily: "Graphik-Light",
    fontSize: " 16px",
    lineHeight: 2,
    textAlign: "left",
    fontWeight: 300,
    marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  }),
  detail1: (props) => ({
    color: props.activeTheme == "lightTheme" ? "#090909" : "#ffff",
    fontFamily: "Graphik-Light",
    fontSize: " 16px",
    lineHeight: 2,
    textAlign: "left",
    fontWeight: 300,
    marginBottom: "50px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  }),
  borderBottom: {
    border: "0.5px solid #007fc1",
    width: "58px",
    bottom: "-26%",
    position: "absolute",
    left: "17%",
    opacity: 0.4,
    textAlign: "right",
  },

  mainContainer: {
    display: "flex",
    flexDirection: "column",
    minHeight: "410px",
    alignItems: "center",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    "& > :not(style)": {
      margin: 5,
      marginRight: 16,
    },
  },
  link: {
    textDecoration: "none !important",
    color: "#007fc1",
  },
}));

const TermsAnsCondition = () => {
  const { t } = useTranslation();
  const activeTheme = useSelector((state) => state.theme.theme);
  const classes = useStyles({ activeTheme });

  return (
    <div className={classes.root}>
      <Container>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "& > :not(style)": {
              margin: 10,
            },
          }}
        >
          <Box className={classes.iconContainer}>
            <Box className={classes.imageContainer}>
              <img src={Bullet} alt="bullet" />
            </Box>
            <Typography
              variant="body1"
              className={classes.h1}
              id="Privacypolicy"
            >
              {t("terms.condition")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" className={classes.detail}>
              {t("terms.agree")}
              <ol type="1">
                <li> {t("terms.agree1")}</li>
                <li> {t("terms.agree2")}</li>
                <li> {t("terms.agree3")}</li>
              </ol>
              {t("terms.parah")}
            </Typography>
          </Box>
          <Box className={classes.iconContainer}>
            <Box className={classes.imageContainer}>
              <img src={Bullet} alt="bullet" />
            </Box>
            <Typography variant="body1" className={classes.h1} id="InfoCollect">
              {t("terms.scope")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" className={classes.detail}>
              {t("terms.scopeParah")}
              <ul>
                <li>{t("terms.scope1")}</li>
                <li>{t("terms.scope2")}</li>
                <li>{t("terms.scope3")}</li>
                <li>{t("terms.scope4")}</li>
                <li>{t("terms.scope5")}</li>
              </ul>
              {t("terms.scopeLast")}
            </Typography>
          </Box>

          <Box className={classes.iconContainer}>
            <Box className={classes.imageContainer}>
              <img src={Bullet} alt="bullet" />
            </Box>
            <Typography variant="body1" className={classes.h1} id="Infouse">
              {t("terms.accept")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" className={classes.detail}>
              <ul>
                <li>{t("terms.accept1")}</li>
                <li>{t("terms.accept2")}</li>
                <li>{t("terms.accept3")}</li>
                <li>{t("terms.accept4")}</li>
                <li>{t("terms.accept5")}</li>
                <li>{t("terms.accept6")}</li>
                <li>{t("terms.accept7")}</li>
                <li>{t("terms.accept8")}</li>
                <li>{t("terms.accept9")}</li>
                <li>{t("terms.accept10")}</li>
              </ul>
            </Typography>
          </Box>
          <Box className={classes.iconContainer}>
            <Box className={classes.imageContainer}>
              <img src={Bullet} alt="bullet" />
            </Box>
            <Typography variant="body1" className={classes.h1} id="Infoprotect">
              {t("terms.service")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" className={classes.detail}>
              <ul>
                <li>{t("terms.service1")}</li>
                <li>{t("terms.service2")}</li>
                <li>{t("terms.service3")}</li>
                <li>{t("terms.service4")}</li>
                <li>{t("terms.service5")}</li>
                <ul>
                  <li>{t("terms.service6")}</li>
                  <li>{t("terms.service7")}</li>
                  <li>{t("terms.service8")}</li>
                  <li>{t("terms.service9")}</li>
                  <li>{t("terms.service10")}</li>
                  <li>{t("terms.service11")}</li>
                </ul>
                <li>{t("terms.service12")}</li>
                <li>{t("terms.service13")}</li>
                <li>{t("terms.service14")}</li>
                <li>{t("terms.service15")}</li>
              </ul>
            </Typography>
          </Box>
          <Box className={classes.iconContainer}>
            <Box className={classes.imageContainer}>
              <img src={Bullet} alt="bullet" />
            </Box>
            <Typography variant="body1" className={classes.h1} id="Infoshare">
              {t("terms.contact")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" className={classes.detail1}>
              {t("terms.contactText")}
              <br /> {t("terms.contactAt")}{" "}
              <a
                className={classes.link}
                href="mailto:info@creatachain.io"
                rel="noreferrer"
              >
                info@creatachain.io
              </a>
            </Typography>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default TermsAnsCondition;
