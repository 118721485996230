import GraphikLight from "../../assets/fonts/Graphik-Light.woff";
import GraphikExtraLight from "../../assets/fonts/Graphik-Extralight.woff";
import MetropolisRegular from "../../assets/fonts/Metropolis-Regular.woff";
import MetropolisBold from "../../assets/fonts/Metropolis-Bold.woff";
import GraphikRegular from "../../assets/fonts/Graphik-Regular.woff";
import GraphikMedium from "../../assets/fonts/Graphik-Medium.woff";
import GraphikBold from "../../assets/fonts/Graphik-Bold.woff";

const metropolis = {
    fontFamily: "Metropolis-Regular",
    fontStyle: "normal",
    fontDisplay: "block",
    fontWeight: "bold",
    src: `
    local('Metropolis-Regular'),
    url(${MetropolisRegular}) format('woff')
  `,
    unicodeRange:
        "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};
const metropolisBold = {
    fontFamily: "Metropolis-Bold",
    fontStyle: "normal",
    fontDisplay: "block",
    fontWeight: "bold",
    src: `
    local('Metropolis-Bold"'),
    url(${MetropolisBold}) format('woff')
  `,
    unicodeRange:
        "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};
const regularFont = {
    fontFamily: "Graphik-Regular",
    fontStyle: "normal",
    fontDisplay: "block",
    fontWeight: "normal",
    src: `
    local('Graphik-Regular'),
    url(${GraphikRegular}) format('woff')
  `,
    unicodeRange:
        "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};
const lightfont = {
    fontFamily: "Graphik-Extralight",
    fontStyle: "normal",
    fontDisplay: "block",
    fontWeight: "normal",
    src: `
    local('Graphik-Extralight'),
    url(${GraphikLight}) format('woff')
  `,
    unicodeRange:
        "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};
const extralightfont = {
    fontFamily: "Graphik-Light",
    fontStyle: "normal",
    fontDisplay: "block",
    fontWeight: "normal",
    src: `
    local('Graphik-Light'),
    url(${GraphikExtraLight}) format('woff')
  `,
    unicodeRange:
        "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};
const graphikMedium = {
    fontFamily: "Graphik-Medium",
    fontStyle: "normal",
    fontDisplay: "block",
    fontWeight: "normal",
    src: `
    local('Graphik-Medium'),
    url(${GraphikMedium}) format('woff')
  `,
    unicodeRange:
        "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};
const graphikBold = {
    fontFamily: "Graphik-Bold",
    fontStyle: "normal",
    fontDisplay: "block",
    fontWeight: "normal",
    src: `
    local('Graphik-Bold'),
    url(${GraphikBold}) format('woff')
  `,
    unicodeRange:
        "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const arr = [
    regularFont,
    metropolis,
    lightfont,
    graphikMedium,
    extralightfont,
    graphikBold,
    metropolisBold,
];
export default arr;
