import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
    Typography,
    TextField,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    CircularProgress,
    InputAdornment,
    Box,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { restoreFile } from "../../assets/images";
import { lightTheme } from "../../vars/theme";
import { useDispatch, useSelector } from "react-redux";
import { login, setEncryptedData, setMenomicLength, userUnlockWallet } from "../../actions";
import CryptoService from "../../services/cryptoService";
import { useHistory } from "react-router-dom";
import fileEncryptionService from "../../services/fileEncryptionService";
import { encryptionConst } from "../../utils/contant";
import creata from "../../services/creata";

const ioCreata = require("io-creata-js");

// const activeTheme = localStorage.getItem("theme");

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h4">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const useStyles = makeStyles((theme) => ({
    // dialogRoot: {

    // },
    formContainer: {
        maxWidth: "600px",
        width: "100%",
        margin: "auto",
    },
    unlockButton: {
        backgroundColor: "transparent",
        color: theme.palette.secondary.main,
        fontFamily: "Graphik-Light",
        width: "170px",
        [theme.breakpoints.down("xs")]: {
            width: "auto",
            padding: "5px 20px",
        },
        position: "relative",
    },
    margin: {
        margin: theme.spacing(1),
    },
    textField: {
        width: "100%",
        maxWidth: "150px",
        boxShadow: "unset",
        borderRadius: "unset",
        "& .MuiInput-underline:before": {
            // borderBottom: `1px solid ${theme.palette.secondary.main}`,
            borderColor: theme.palette.secondary.main,
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderColor: theme.palette.secondary.main,
        },
        "& .MuiInput-underline:hover": {
            // borderBottom: `1px solid ${theme.palette.secondary.main}`,
            borderColor: theme.palette.secondary.main,
        },
        "& .MuiInput-underline:after": {
            // borderBottom: `1px solid ${theme.palette.secondary.main}`,
            borderColor: theme.palette.secondary.main,
        },
        "& .MuiInputBase-input": {
            fontFamily: "Graphik-light",
            color: theme.palette.secondary.main,
        },
        [theme.breakpoints.down("xs")]: {
            maxWidth: "95%",
        },
    },
    inputAdormentColor: {
        color: theme.palette.secondary.main,
    },

    field: {
        border: `1px solid ${theme.palette.secondary.main}`,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        padding: "20px 0px",
        color: theme.palette.secondary.main,
    },
    button: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px 0px",
    },
    dialogBoxWidth: {
        maxWidth: "508px",
        padding: "10px 12px",
        border: "solid 1px transparent",
        backgroundColor: theme.palette.primary.light,
        backdropFilter: "blur(10px)",
        borderRadius: 0,
    },
    leftLine: {
        position: "absolute",
        width: "22.7px",
        height: "2px",
        margin: "22px -12px",
        backgroundColor: "#25c5f7",
        left: 0,
        zIndex: 1,
    },
    maskLeft: (props) => ({
        position: "absolute",
        margin: "15px -12px",
        left: 0,
        width: "22.7px",
        height: "16px",
        background: props.activeTheme === lightTheme ? "#eef2f5" : "rgb(39 35 42)",
        zIndex: 1,
    }),
    rightLine: {
        position: "absolute",
        width: "22.7px",
        height: "2px",
        margin: "-25px -12px",
        right: "0px",
        backgroundColor: "#25c5f7",
        zIndex: 1,
    },
    maskRight: (props) => ({
        position: "absolute",
        margin: "-32px -12px",
        width: "22.7px",
        height: "16px",
        right: "0px",
        zIndex: 1,
        background: props.activeTheme === lightTheme ? "#eef2f5" : "rgb(39 35 42)",
    }),
    imgRestor: {
        margin: "20px auto 0px",
        objectFit: "contain",
    },
    errorSpan: {
        width: "90%",
        color: "red",
        fontWeight: 700,
        fontSize: 12,
        marginTop: 14,
        alignItems: "center",
    },
    title: {
        color: theme.palette.secondary.main,
    },
}));

const MnemonicPhraseModal = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const Password = history.location.password;
    const activeTheme = useSelector((state) => state.theme.theme);
    const { t, li8n } = useTranslation();
    const mnemonicLength = useSelector((state) => state.wallet.mnemonicLength);
    const classes = useStyles({ activeTheme });
    const { isMnemonicModalOpen, setIsMnemonicModalOpen } = props;

    const [state, setState] = useState({
        isOpen: false,
        isError: false,
        isInvalid: false,
        isCancel: false,
        isOpenReceive: false,
        words: Array(mnemonicLength).fill(""),
    });
    const [pasted, setPasted] = React.useState(false);
    const [mnemonicLoading, setMnemonicLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);

    const [invalidMnemonics, setInvalidMnemonics] = useState(false);

    const handleInputChange = (e, index) => {
        if (pasted) {
            const data = e.target.value;
            const words = data.match(/[a-zA-Z]+/g);
            setPasted(false);
            if (words.length === mnemonicLength) {
                setState({ ...state, words: words, hasError: false });
            }
        } else {
            const { words } = state;
            const newWords = [...words];
            newWords[index] = e.target.value.toLowerCase().trim();
            setState({ ...state, words: newWords, hasError: false });
        }
    };
    const handlePaste = () => {
        setPasted(true);
    };
    const [isPassword, setIsPassword] = useState({
        password: Password,
        confirmPassword: "",
        touched: {
            password: false,
            confirmPassword: false,
        },
    });

    const submitMnemonicPhraseHandler = async (e) => {
        e.preventDefault();
        await setMnemonicLoading(true);
        const mnemonicPhrase = Object.values(state.words).join(" ");
        const isValidate = CryptoService.validateMnemonic(mnemonicPhrase);
        setIsModal(false);

        if (isValidate) {
            createWalletFile(mnemonicPhrase, isPassword.password);
            setMnemonicLoading(false);

            dispatch(login());

            const chainId = process.env.REACT_APP_CHAIN_ID;
            const creata = ioCreata.network(
                `${process.env.REACT_APP_CREATACHAIN_IOCREATA_URL}`,
                chainId,
            );
            creata.setPath("m/44'/118'/0'/0/0"); //hd path for creata
            const address = creata.getAddress(mnemonicPhrase);
            // const ecpairPriv = creata.getECPairPriv(mnemonicPhrase);
            await dispatch({
                type: "SET_ADDRESS",
                payload: { address: address, ecpairPriv: "" },
            });

            history.push("/dashboard");
        } else {
            setMnemonicLoading(false);
            setInvalidMnemonics(true);
            setState({
                ...state,
                isOpen: true,
                isError: false,
                isInvalid: true,
                isCancel: false,
            });
        }
    };

    const getNewAccountFromTemplate = ({ index, timestamp, publicKey, secretKey, address }) => ({
        displayName: index > 0 ? `Account ${index}` : "Main Account",
        created: timestamp,
        path: `0/0/${index}`,
        publicKey,
        secretKey,
        address,
    });

    const createWalletFile = async (mnemonicPhrase, password) => {
        try {
            const walletAddress = creata.getAddress(mnemonicPhrase);
            const timestamp = new Date().toISOString().replace(/:/g, "-");
            const mnemonic = mnemonicPhrase;

            const dataToEncrypt = {
                mnemonic: mnemonic,
                accounts: [
                    getNewAccountFromTemplate({
                        address: walletAddress,
                        index: 0,
                        timestamp,
                        publicKey: {},
                        secretKey: {},
                    }),
                ],
                contacts: [],
            };
            const meta = {
                displayName: "Main Wallet",
                created: timestamp,
                netId: 0,
                meta: { salt: encryptionConst.DEFAULT_SALT },
            };
            dispatch(userUnlockWallet(meta, dataToEncrypt.accounts, ""));
            const key = fileEncryptionService.createEncryptionKey(password);
            const encryptedAccountsData = fileEncryptionService.encryptData({
                data: JSON.stringify(dataToEncrypt),
                key,
            });
            dispatch(setEncryptedData(encryptedAccountsData));
            return {
                error: null,
                meta,
                accounts: dataToEncrypt.accounts,
                mnemonic: mnemonic,
            };
        } catch (error) {
            return { error, meta: null };
        }
    };

    const handleMnemonicModalClose = () => {
        setIsMnemonicModalOpen(false);
        setInvalidMnemonics(false);
        setIsPassword({
            ...isPassword,
            touched: {
                password: false,
                confirmPassword: false,
            },
        });
        setState({
            ...state,
            isInvalid: false,
        });
    };

    useEffect(() => {
        setState({ ...state, words: Array(mnemonicLength).fill("") });
    }, [mnemonicLength]);

    return (
        <Dialog
            className={classes.dialogRoot}
            open={isMnemonicModalOpen}
            keepMounted
            onClose={handleMnemonicModalClose}
            aria-labelledby="responsive-dialog-title"
            classes={{
                paperWidthSm: classes.dialogBoxWidth,
            }}>
            <img src={restoreFile} alt="restoreImg" className={classes.imgRestor} />
            <DialogContent>
                <form
                    onSubmit={submitMnemonicPhraseHandler}
                    className={classes.formContainer}
                    autoComplete="off">
                    <Typography
                        variant="h4"
                        style={{ margin: "16px 0px" }}
                        className={classes.title}>
                        {t("mnemonicphrase.mainhead")}
                    </Typography>
                    <Box className={classes.field}>
                        <Box>
                            {state?.words?.map((item, index) => (
                                <React.Fragment key={index}>
                                    <TextField
                                        error={invalidMnemonics}
                                        value={state.words[index]}
                                        onPaste={handlePaste}
                                        onChange={(e) => handleInputChange(e, index)}
                                        className={clsx(classes.margin, classes.textField)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment
                                                    position="start"
                                                    className={classes.inputAdormentColor}>
                                                    {index + 1}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </React.Fragment>
                            ))}
                        </Box>
                    </Box>

                    <div style={{ width: "100%", textAlign: "center" }}>
                        {state.isInvalid ? (
                            <span className={classes.errorSpan}>
                                {t("mnemonicphrase.Mnemonicphrasenotmatched")}
                            </span>
                        ) : null}
                    </div>
                    <Box className={classes.button}>
                        <Button
                            className={classes.unlockButton}
                            onClick={submitMnemonicPhraseHandler}
                            disabled={mnemonicLoading}
                            variant="outlined"
                            color="secondary"
                            type="submit">
                            {mnemonicLoading ? (
                                <CircularProgress
                                    variant="indeterminate"
                                    disableShrink
                                    className={classes.top}
                                    classes={{
                                        circle: classes.circle,
                                    }}
                                    size={30}
                                    thickness={4}
                                />
                            ) : (
                                <>{t("mnemonicphrase.UnlockWallet")}</>
                            )}
                            <span className={classes.maskLeft} />
                            <span className={classes.leftLine} />
                            <span className={classes.maskRight} />
                            <span className={classes.rightLine} />
                        </Button>
                    </Box>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default MnemonicPhraseModal;
